import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService} from '../../shared/services/login.service';
import { MasterService } from '../../shared/services/master.service';
import { FeeReceiptService } from '../../shared/services/feereceipt.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../_modal';
import { FeeReceipt} from '../../shared/model/fee-receipt.model';
import { StudentFeeReceipt} from '../../shared/model/student-fee-receipt.model';
import { StudentFeeReceiptDetailList } from '../../shared/model/student-fee-receipt-detail-List.model';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';


import { StudentFeePay} from '../../shared/model/student-fee-pay.model';
import { StudentFeeResponseDetailsByOnline} from '../../shared/model/student-fee-responsedetailsbyonline.model';

declare var openPay: Function;


@Component({
  selector: 'app-fee-receipt-online',
  templateUrl: './fee-receipt-online.component.html',
  styleUrls: ['./fee-receipt-online.component.scss']
})
export class FeeReceiptOnlineComponent implements OnInit {
  selectedSession: string = '';
  FeeReceiptOnlineForm: FormGroup;
  isSMSorEmailChecked: boolean = true;
  isRecordFound: boolean = true;
  isTextBoxDisabled: boolean = true;
  isStudentDetailRecordFound: boolean = false;
  isBtnAdjust: boolean = false;
  isBtnRevert: boolean = false;
  isBtnSave: boolean = false;
  public showSave:boolean = false;
  isBtnPrint: boolean = false;
  isBtnSMS: boolean = false;
  isBtnMail: boolean = false;
  isBtnReload: boolean = false;
  sessionYear: string = '';
  modalId = 'paymentHistoryModal';
  searchPayableAmt : number = 0;
  public searchAdmNo: any ='';
  public studentDetails = [];
  public uid = '';
  public studentinfo:any;
  public StudentFeeReceiptDetail:any = [];
  public StudentFeeReceiptHistory:any;
  public monthwiseList:any = [];
  public domain: string = null;
  FeeReceiptModel = new FeeReceipt();
  StudentFeeReceipt = new StudentFeeReceipt();
  StudentFeeReceiptDetailList = new StudentFeeReceiptDetailList();

  
  StudentFeePay = new StudentFeePay();
  atomTokenId : string;  
  merchId:string = "";
  schId:string = "";
  suId:string = "";
  StudentFeeResponseDetailsByOnline= new StudentFeeResponseDetailsByOnline();

  paymentmessage : string;
  statusCode : string;
  bankTxnId : string;
  atomTxnId : string;
  txnCompleteDate : string;
  amount : string;
  merchTxnId: string;
  merchTxnDate: string;



  sessions: Array<any> = new Array<any>();
  months: Array<any> = new Array<any>();
  oddMonths: Array<any> = new Array<any>();
  evenMonths: Array<any> = new Array<any>();
  bankList: Array<any> = new Array<any>();
  StudentFeeReceiptDetailModel: Array<StudentFeeReceiptDetailList> = new Array<StudentFeeReceiptDetailList>();

  public message: string = null;
  public oldsuid: string = '';
  isPayableAmt: boolean = true;

  constructor(private formBuilder: FormBuilder,private modalService : ModalService, private masterService:MasterService, 
    private feereceiptService: FeeReceiptService,private toastr: ToastrService,private loginService: LoginService, private router: Router,
    @Inject(DOCUMENT) private document: any
    ) {
      this.feereceiptService.GetStudentOldAdmissionNo(JSON.parse(localStorage.getItem('currentUser'))["userId"]).subscribe(st => {   
        this.oldsuid = st[0]['stAdmissionNo'];
        this.FeeReceiptOnlineForm.controls['admissionNumber'].setValue(this.oldsuid);
       });
       
  }

  ngOnInit() {    
    this.isBtnSave = false;   
    this.masterService.getSessionList().subscribe(lst => { this.sessions = lst;
      let defaultSession = this.sessions.filter(sm => sm.sm_CurrentSession === true);
      if(defaultSession.length === 0) {
        this.FeeReceiptOnlineForm.controls['session'].setValue(0);
      } else {
        this.FeeReceiptOnlineForm.controls['session'].setValue(defaultSession[0].sm_Sessionid);
        this.selectedSession = defaultSession[0].sm_Sessionid;
      }

      const dt = new Date();
      this.FeeReceiptOnlineForm.controls['receiptDate'].setValue({year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()});
      
      // if(this.oldsuid=''){
      //   this.feereceiptService.GetStudentOldAdmissionNo(JSON.parse(localStorage.getItem('currentUser'))["userId"]).subscribe(st => {   
      //     this.oldsuid = st[0]['stAdmissionNo'];
      //     this.FeeReceiptOnlineForm.controls['admissionNumber'].setValue(this.oldsuid);
      //    });
      // }


      this.getdetailsOfStudent();
    });
    
    
    this.masterService.getBankList().subscribe((lst: any[]) => { this.bankList = lst;});
    this.FeeReceiptOnlineForm = this.formBuilder.group({
      session: ['null', Validators.required],
      receiptDate: [''],
      admissionNumber: ['', Validators.required],
      name: [''],
      class: [''],
      section: [''],
      rollNo: [''],
      fatherName: [''],
      motherName: [''],
      email: [''],
      mobile: [''],
      isSendSMSorEmailChecked: [false],
      isChequeChecked: [true],
      chequeOrDDNo: [''],
      chequeOrDDDate: [''],
      bankName: ['0'],
      branchDetails: [''],
      remarks: [''],
      payableAmount: ['', [Validators.pattern("^[0-9]*$")]],
      month: [''],
      type:['Receipt'],
      mode:['Online']
    });
    
  }

 
  onChangeSessionDDL(event: { target: { value: string; }; }) {
    this.selectedSession = event.target.value;
  }

  getSessionYear(event: { target: { value: number; }; }) {
    if (this.selectedSession != null || this.selectedSession != '') {
      this.sessionYear = (event.target.value <= 3) ? ' ' + this.selectedSession.split('-')[1] :
        ' ' + this.selectedSession.split('-')[0];
    }
  }

  getdetailsOfStudent(){     
     this.FeeReceiptOnlineForm.controls['admissionNumber'].setValue(this.oldsuid);
     const uid = this.FeeReceiptOnlineForm.controls.admissionNumber.value;
    
     // this.feereceiptService.getPendingfromprevioussession(uid, this.selectedSession).subscribe((pst: any) => {
    //   if(pst != null){
    //   if(pst["pendingval"] > 0 ){
    //     this.toastr.error("This student has pending amount in previous session!", "Error");
    //   }
    // }
    // });

    this.feereceiptService.getStudentFeecalculationOnline(uid, this.selectedSession).subscribe((lst: any[]) =>{
      this.studentDetails = lst;
      this.studentinfo = this.studentDetails['StudentInfo'];
      this.StudentFeeReceiptDetail = this.studentDetails['StudentFeeReceiptDetail'];
      this.StudentFeeReceiptDetail = this.StudentFeeReceiptDetail.filter(y => y.frd_fname !== "Total" && y.pendingval > 0);
      this.FeeReceiptOnlineForm.controls['name'].setValue(this.studentinfo.su_suname);
      this.FeeReceiptOnlineForm.controls['class'].setValue(this.studentinfo.sd_sdname);
      this.FeeReceiptOnlineForm.controls['section'].setValue(this.studentinfo.cs_csname);
      this.FeeReceiptOnlineForm.controls['rollNo'].setValue(this.studentinfo.sud_rollno);
      this.FeeReceiptOnlineForm.controls['fatherName'].setValue(this.studentinfo.su_fathname);
      this.FeeReceiptOnlineForm.controls['motherName'].setValue(this.studentinfo.su_mothname);
      this.FeeReceiptOnlineForm.controls['email'].setValue(this.studentinfo.su_resiemail);
      this.FeeReceiptOnlineForm.controls['mobile'].setValue(this.studentinfo.su_resimobile);
      
    });

    this.feereceiptService.GetStudentPendingAmountMonthWiseList(uid, this.selectedSession).subscribe((stm: any) => {
      this.monthwiseList =this.CheckConditionForMonth(stm);
      
      this.isStudentDetailRecordFound = true;
      this.isBtnAdjust = true;
      this.isBtnSave = false;
      this.showSave = false;
    });
  }

  CheckConditionForMonth(data: any) {
    const CurrentDate = new Date();
    let Count = 0;
    let TotalPayableAmt : number = 0;
    data.forEach((element: any) => {
      let CustomDate = new Date(element.frd_Year+'-'+element.frd_MonthName+'-01');
       if((CustomDate != null && CurrentDate !=null) && (CustomDate) < (CurrentDate)){
       data[Count].Ischeck=true;
       data[Count].isDisabled=true;
       TotalPayableAmt += element.pendingval
      }
      else{
        data[Count].Ischeck=false;
        data[Count].isDisabled=false;
      }
      Count++;
    });
    this.FeeReceiptOnlineForm.controls.payableAmount.setValue(TotalPayableAmt);
    localStorage.setItem('PayableAmt', TotalPayableAmt.toString());
    this.searchPayableAmt = TotalPayableAmt;
    return data;
  }

  onMonthChange(e:any) {
    let IndexData: any[];
    IndexData = this.monthwiseList.filter((it) => {
      return it.frd_MonthName.includes(e.target.value);
    });
    const index = this.monthwiseList.findIndex((x: { frd_MonthName: any; }) => x.frd_MonthName === e.target.value);
    if (e.target.checked) {
        for(var x of  this.monthwiseList) {
        if(!x.Ischeck){
          if(x.frd_MonthName === e.target.value){
            this.searchPayableAmt += IndexData[0].pendingval;
            this.FeeReceiptOnlineForm.controls.payableAmount.setValue(this.searchPayableAmt);
            this.monthwiseList[index].Ischeck = true;
            this.months.push(e.target.value);
            break;
          }
          else{
            this.toastr.error("Please pay amount in previous month!", "Error");
            e.target.checked = false;
            break;
          }
        }
      };
    }
    else{
      let num:number = 12;
      let i:number; 
      for(i=index+1; i<num; i++){
        if(this.monthwiseList[i].Ischeck){
          e.target.checked = true;
         return false;
        }
      }
      this.searchPayableAmt = this.searchPayableAmt - IndexData[0].pendingval;
      this.FeeReceiptOnlineForm.controls.payableAmount.setValue(this.searchPayableAmt);
      this.monthwiseList[index].Ischeck = false;
      this.months = this.months.filter(obj => obj !== e.target.value);
    }
  }

  adjustfee(){
    let i = 0;
    this.months;
    localStorage.setItem('PayableAmt', this.searchPayableAmt.toString());
    this.StudentFeeReceiptDetail.forEach((element: any) => {
      if(element.pendingval > 0){
        let PayableAmt = element.pendingval - this.searchPayableAmt;
        if(this.searchPayableAmt != 0){
          if(PayableAmt <= 0){
            this.StudentFeeReceiptDetail[i].frd_paidval = element.pendingval;
            this.searchPayableAmt = this.searchPayableAmt - element.pendingval;
            this.StudentFeeReceiptDetail[i].pendingval = 0;
          }
          else{
            this.StudentFeeReceiptDetail[i].frd_paidval = this.searchPayableAmt;
            this.StudentFeeReceiptDetail[i].pendingval = element.pendingval - this.searchPayableAmt;
            this.searchPayableAmt = 0;
          }
          if(this.months.filter(obj => obj == element.frd_MonthName).length > 0){
            this.StudentFeeReceiptDetail[i].frd_status = "Advance";
          }
          this.isBtnRevert = true;
          this.isBtnSave = true;
          this.isBtnAdjust = false;
          this.showSave = true;    
          this.isPayableAmt = false;      
        }
    }
    i++;
    });
    
  }

  Revertfee(){
    this.feereceiptService.getStudentFeecalculation(this.FeeReceiptOnlineForm.controls.admissionNumber.value, this.selectedSession).subscribe((lst: any[]) =>{
      this.studentDetails = lst;
      this.StudentFeeReceiptDetail = this.studentDetails['StudentFeeReceiptDetail'];
      this.StudentFeeReceiptDetail = this.StudentFeeReceiptDetail.filter(y => y.frd_fname !== "Total" && y.pendingval > 0);
    });

    this.feereceiptService.GetStudentPendingAmountMonthWiseList(this.FeeReceiptOnlineForm.controls.admissionNumber.value, this.selectedSession).subscribe((stm: any) => {
      this.monthwiseList =this.CheckConditionForMonth(stm);
    });
    localStorage.setItem('PayableAmt', this.searchPayableAmt.toString());
    this.isBtnAdjust = true;
    this.isBtnRevert = false;
    this.isBtnSave = false;

  }

  openModal(id: string) {
    const uid = this.FeeReceiptOnlineForm.controls.admissionNumber.value;
    this.feereceiptService.getStudentFeecalculation(uid, this.selectedSession).subscribe((lst: any[]) =>{
      if(lst["StudentFeeReceiptHistory"].length > 0){
      this.StudentFeeReceiptHistory = lst["StudentFeeReceiptHistory"];
      this.modalService.open(id);
    }
    else{
      this.toastr.error("No Records Found!", "Error");
    }
    });
  }

  SavefeeReceipt(merchTxnId,merchTxnDate){
    let ChequeDate = null;
    const FeeReceiptobj = {
      schoolId:(this.loginService.currentUserSubject.value ).schoolId,
      sessionId : this.FeeReceiptOnlineForm.controls.session.value,
      receiptDate: this.FeeReceiptOnlineForm.controls.receiptDate.value,
      type : this.FeeReceiptOnlineForm.controls.type.value,
      mode : this.FeeReceiptOnlineForm.controls.mode.value,
      isSendSMSorEmailChecked : this.FeeReceiptOnlineForm.controls.isSendSMSorEmailChecked.value,
      admissionNumber : this.FeeReceiptOnlineForm.controls.admissionNumber.value,
      remarks : this.FeeReceiptOnlineForm.controls.remarks.value,
      // chequeOrDDNo : this.FeeReceiptOnlineForm.controls.chequeOrDDNo.value,
      // chequeOrDDDate : this.FeeReceiptOnlineForm.controls.chequeOrDDDate.value,
      chequeOrDDNo : merchTxnId,
      chequeOrDDDate : merchTxnDate,
      bankId : this.FeeReceiptOnlineForm.controls.bankName.value == null ? 0 :  this.FeeReceiptOnlineForm.controls.bankName.value,
      payableAmount : localStorage.getItem('PayableAmt'),
    };
    
    this.StudentFeeReceipt.fr_suid  = this.studentinfo.su_suid;
    this.StudentFeeReceipt.fr_modeofpayment = FeeReceiptobj.mode;   
    this.StudentFeeReceipt.fr_chqtranno = FeeReceiptobj.chequeOrDDNo;   
    // if(FeeReceiptobj.chequeOrDDDate.month) {
    //   ChequeDate  = FeeReceiptobj.chequeOrDDDate.year+'-'+FeeReceiptobj.chequeOrDDDate.month+'-'+FeeReceiptobj.chequeOrDDDate.day;
    // }
    this.StudentFeeReceipt.fr_chqtrandt = FeeReceiptobj.chequeOrDDDate
    
    this.StudentFeeReceipt.fr_bkid =  FeeReceiptobj.bankId
    this.StudentFeeReceipt.fr_Totpaidamt = FeeReceiptobj.payableAmount 
    this.StudentFeeReceipt.fr_rmrks = FeeReceiptobj.remarks 
    this.StudentFeeReceiptDetail = this.StudentFeeReceiptDetail.filter(x=>x.frd_paidval !== null);
    
    this.StudentFeeReceiptDetail.forEach((x: any) => {
      if(x.frd_paidval != null){
        this.StudentFeeReceiptDetailList = new StudentFeeReceiptDetailList();
        this.StudentFeeReceiptDetailList.frd_MonthName = x.frd_MonthName;  
        this.StudentFeeReceiptDetailList.frd_Year = x.frd_Year; 
        this.StudentFeeReceiptDetailList.frd_fhsid = x.frd_fhsid; 
        this.StudentFeeReceiptDetailList.frd_fname = x.frd_fname;
        this.StudentFeeReceiptDetailList.frd_newstudentdiscount = x.frd_newstudentdiscount;
        this.StudentFeeReceiptDetailList.frd_dayscholordiscount = x.frd_dayscholordiscount;
        this.StudentFeeReceiptDetailList.frd_dayboardingdiscount = x.frd_dayboardingdiscount;
        this.StudentFeeReceiptDetailList.frd_hosteldiscount = x.frd_hosteldiscount; 
        this.StudentFeeReceiptDetailList.frd_ewsdiscount = x.frd_ewsdiscount;
        this.StudentFeeReceiptDetailList.frd_staffdiscount = x.frd_staffdiscount;
        this.StudentFeeReceiptDetailList.frd_scholorshipdiscount = x.frd_scholorshipdiscount;
        this.StudentFeeReceiptDetailList.frd_girldiscount = x.frd_girldiscount;
        this.StudentFeeReceiptDetailList.frd_siblingdiscount = x.frd_siblingdiscount;
        this.StudentFeeReceiptDetailList.frd_individualdiscount = x.frd_individualdiscount;
        this.StudentFeeReceiptDetailList.frd_totalval = x.frd_totalval;
        this.StudentFeeReceiptDetailList.frd_totaldiscount = x.frd_totaldiscount;
        this.StudentFeeReceiptDetailList.frd_remainingval = x.frd_remainingval;
        this.StudentFeeReceiptDetailList.paidval = x.frd_paidval;
        this.StudentFeeReceiptDetailList.pendingval = x.pendingval;
        this.StudentFeeReceiptDetailList.frd_paidval = x.frd_paidval;
        this.StudentFeeReceiptDetailList.frd_status = x.frd_status;
        this.StudentFeeReceiptDetailModel.push(this.StudentFeeReceiptDetailList);
      }
    });

    this.FeeReceiptModel.Type = FeeReceiptobj.type;
    this.FeeReceiptModel.ReceiptDate = FeeReceiptobj.receiptDate.year+'-'+FeeReceiptobj.receiptDate.month
    +'-'+FeeReceiptobj.receiptDate.day;
    this.FeeReceiptModel.SchoolId = FeeReceiptobj.schoolId;
    this.FeeReceiptModel.SessionId = FeeReceiptobj.sessionId;
    this.FeeReceiptModel.StudentFeeReceipt = this.StudentFeeReceipt;
    this.FeeReceiptModel.StudentFeeReceiptDetail = this.StudentFeeReceiptDetailModel;
    this.FeeReceiptModel.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"]
    // this.feereceiptService.saveStudentFeecForm(this.FeeReceiptModel).subscribe((lst: any[]) =>{
    //   this.toastr.success(lst["message"], "Success");
    //    window.location.reload();

    // this.feereceiptService.saveStudentFeecForm(this.FeeReceiptModel).subscribe(m => {
    this.feereceiptService.saveStudentFeeTempForm(this.FeeReceiptModel).subscribe(m => {
    console.log(m);
    // this.feereceiptService.frid = m.id;
    // this.feereceiptService.oldsuid = this.FeeReceiptOnlineForm.controls.admissionNumber.value;
    // this.message = m.message;
    // this.toastr.success(this.message);
    // this.isBtnPrint = true;
    // this.isBtnSMS = true;
    // this.isBtnMail = true;
    // this.isBtnReload = true;
    // this.isBtnRevert = false;
    // this.isBtnSave = false;

    // this.PrintfeeReceipt();

    });
  }

  Payment(){    
    const FeeReceiptobj = {
      // schoolId:(this.loginService.currentUserSubject.value ).schoolId,
      sessionId : this.FeeReceiptOnlineForm.controls.session.value,      
      admissionNumber : this.FeeReceiptOnlineForm.controls.admissionNumber.value,     
      // payableAmount : localStorage.getItem('PayableAmt'),
    };
    this.StudentFeePay.schId  = (this.loginService.currentUserSubject.value ).schoolId;    
    this.StudentFeePay.suId  = String(this.studentinfo.su_suid);   
    this.StudentFeePay.sesId = String(FeeReceiptobj.sessionId);   
    this.StudentFeePay.amount  = localStorage.getItem('PayableAmt');
    this.StudentFeePay.custEmail  = this.studentinfo.su_resiemail;
    this.StudentFeePay.custMobile  = this.studentinfo.su_resimobile;  
    this.StudentFeePay.AdNo  = FeeReceiptobj.admissionNumber;  
    this.StudentFeePay.userId  = JSON.parse(localStorage.getItem('currentUser'))["userId"];
   
    this.feereceiptService.ManagePay(this.StudentFeePay).subscribe(result => {
    console.log(result);
   // return result;   
   // this.atomTokenId=result;   
   // console.warn(result);
    //return this.atomTokenId;
    // this.custEmail =this.FeeReceiptOnlineForm.controls['email'].value;
    // this.custMobile=this.FeeReceiptOnlineForm.controls['mobile'].value; this.StudentFeeReturnURL

    this.merchTxnId= result.Item4;
    this.merchTxnDate= result.Item5;

    if(result.Item1>0){
      this.SavefeeReceipt(this.merchTxnId,this.merchTxnDate);
    }
    openPay(result.Item1,result.Item2,result.Item3,result.Item6);
    
    // if(result.Item6="SUCCESS"){
      // this.feereceiptService.ManageResponse().subscribe(result => {
      //   console.log(result);      
      //   this.bankTxnId =result.Item1;
      //   this.atomTxnId =result.Item2;    
      //   this.amount =result.Item3;
      //   this.txnCompleteDate =result.Item4;
      //   this.statusCode =result.Item5;
      //   this.paymentmessage =result.Item6;
      //   });  
    // }   

    });  
  }





  // pay() {
  //   if (this.studentinfo.su_suid > 0) {      
  //     this.router.navigate(['/atompay']);
  //   }
  // }
 

  // TokenId : any;
  // custEmail:string = "rajiv.nandan.singh@gmail.com";
  // custMobile:string = "9999034776";
  // getTokenId() {
  //   this.feereceiptService.tokenId().subscribe((result) => {
  //     this.TokenId = result;
  //     console.warn(result);
  //     return this.TokenId;
  //   })
  // }
  
  
  
  
  
  
  
  onTextboxChange(e: any){
    // if(e.target.value >= localStorage.getItem('PayableAmt')){
    //   this.toastr.error("Payable amount is not greater than selected month", "Error");
    //   this.FeeReceiptOnlineForm.controls.payableAmount.setValue(localStorage.getItem('PayableAmt'));
    //   return;
    // }
  }
  PrintfeeReceipt() {
    this.domain = this.document.location.hostname;
    this.feereceiptService.getPDF(
      this.FeeReceiptModel.SessionId,this.domain, this.FeeReceiptModel.UserId).subscribe((response:any)=>{
        const pdfWindow = window.open("")
    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
    encodeURI(response) + "'></iframe>");
    });
  }

  Reload() {
    window.location.reload();
  }

  SMS() {
    this.feereceiptService.SMS().subscribe((response:any)=>{
      if (response) {
        this.toastr.success(response);
      } else {
        alert('Message not sent');
      }
    });
  }

  Mail(UserId) {
    this.domain = this.document.location.hostname;
    this.feereceiptService.Mail(this.FeeReceiptModel.UserId, this.domain).subscribe((response:any)=>{
      if (response) {
        this.toastr.success(response);
      } else {
        alert('Mail not sent');
      }
    });
  }
}
