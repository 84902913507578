import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { LoginService } from '../../shared/services/login.service';
import { FeeReceiptService } from '../../shared/services/feereceipt.service';
import { DatePipe } from '@angular/common';
import { User } from '../../shared/model/user.model';

@Component({
  selector: 'app-student-fee-receipt',
  templateUrl: './student-fee-receipt.component.html',
  styleUrls: ['./student-fee-receipt.component.scss'],
  
})
export class StudentFeeReceiptComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter:  any = '';
  public userFilter: any ;
  public studentfeeReceiptForm: FormGroup;
  public p:number =1;
  public domain: string = null;
  public userId:number;
  constructor(private router: Router, private stService: StudentService, private feereceiptService: FeeReceiptService,
    private formBuilder: FormBuilder, private toast: ToastrService, private loginService: LoginService,
    @Inject(DOCUMENT) private document: any) {
  
    this.userId = (<User>this.loginService.currentUserSubject.value ).userId;
    this.stService.getMasterList(); 

  }

  ngOnInit() {
      this.studentfeeReceiptForm = this.formBuilder.group({
         SessionId:['']  
    });

    this.stService.masterList.subscribe(m=>{this.staticList=m;})
    this.loadStudent();  }

    loadStudent() {   
    this.stService.getStudentFeeReceiptList_InStudentLogin(this.userId, this.studentfeeReceiptForm.controls['SessionId'].value).
      subscribe(m => {      
        this.stList = m;
        this.stOrgList = m;       
      }); 
  }
  
 PrintfeeReceipt(itm) {
  this.domain = this.document.location.hostname; 
  // this.feereceiptService.frid = itm.fr_frid;
  //   this.feereceiptService.oldsuid = itm.su_oldsuid; 
      this.feereceiptService.getFeeReceiptStatusPDF(itm.fr_frid, itm.su_oldsuid,
        itm.fr_Sessionid, this.domain, this.userId).subscribe((response: any) => {
          const pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
          encodeURI(response) + "'></iframe>");
           }); 
      // this.feereceiptService.getPDF(
      //   itm.fr_Sessionid,this.domain, this.userId).subscribe((response:any)=>{
      //     const pdfWindow = window.open("")
      // pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
      // encodeURI(response) + "'></iframe>");
      // });
 }
}

