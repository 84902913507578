import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-atomresponse',
  templateUrl: './atomresponse.component.html',
  styleUrls: ['./atomresponse.component.css']
})
export class AtomresponseComponent implements OnInit {

  c_data : any;
  status: string ="";
  txn_id: string ="";
  bantxn_id: string ="";

  paymentmessage : string;
  statusCode : string;
  bankTxnId : string;
  atomTxnId : string;
  txnCompleteDate : string;
  amount : string;

  

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    
  } 

}
