import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentService } from '../../shared/services/student.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-atomsuccessresponse',
  templateUrl: './atomsuccessresponse.component.html',
  styleUrls: ['./atomsuccessresponse.component.css']
})
export class AtomsuccessresponseComponent implements OnInit {  
  public FeeList: any[];
  c_data : any;
  status: string ="";
  txn_id: string ="";
  bantxn_id: string ="";
  amount : string;

  // paymentmessage : string;
  // statusCode : string;
  // bankTxnId : string;
  // atomTxnId : string;
  // txnCompleteDate : string;  

  constructor(private route: ActivatedRoute,private stService: StudentService, 
    private toast: ToastrService) { 
     
  }
  
  ngOnInit(): void {   
    this.getresponse();
  }
  getresponse() {   
    this.stService.GetStudentPaymentStatusUserWise().subscribe(FeeList=>{
      this.status =FeeList[0]['fro_message'];
      this.txn_id =FeeList[0]['fro_merchTxnId'];
      this.bantxn_id =FeeList[0]['fro_bankTxnId'];
      this.amount =FeeList[0]['fro_Totpaidamt'];     
  });  
  
  } 

}
