import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from './../../shared/services/report.service';
import { MasterService } from './../../shared/services/master.service';
import { StudentService } from '../../shared/services/student.service';
import { NavbarService } from './../../shared/services/navbar.service';
import {LoginService} from './../../shared/services/login.service';
import { User } from './../../shared/model/user.model';
// import { DateFormatPipe } from './../../shared/pipes/dateformat.pipe';
import { getMatScrollStrategyAlreadyAttachedError } from '@angular/cdk/overlay/scroll/scroll-strategy';
// import { DatePipe,formatDate } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']  
})
export class ReportsComponent implements OnInit {

  public reportsForm: FormGroup;
  showError: Boolean = false;
  public schfeeheadList: any[] = [];
  public busrouteList: any[] = [];
  public EmployeeList: any[] = [];
  public sessionList:any = [];
  public staticList:any = [];
  public MenuList:any = [];
  public id:any;
  public menuid:any;
  public dropDownList:any = [];
  public showClass:boolean = false;
  public showSession:boolean = false;
  public showSection:boolean = false;
  public showType:boolean = false;
  public showRegistration:boolean = false;
  public showNurseryRegistration :boolean = false;
  public showOnlineRegistration :boolean = false;
  public showAdmissionForm :boolean = false;
  public showAdmission:boolean = false;
  public showFeeReceipt:boolean = false;
  public showOldSuid:boolean = false;
  public showFromDate:boolean = false;
  public showToDate:boolean = false;
  public showEmp:boolean = false;
  public showRoute:boolean =false;
  public showFeeHead:boolean=false;
  public showStartDate:boolean=false;
  public domain = '';
  public name:any;
  public UserId:number;
  public ToDate: any;
  public FromDate: any;
  public schid: number = 0;
  public employeeid: number = 0;
  public oldsu: string = '';
  public role: string;
  // public showSubmit:boolean = false;
  public selectedSession: string = '';
  constructor(public router: Router, private formBuilder: FormBuilder,
               private modalService: NgbModal, private reportService: ReportService,
               private stService: StudentService, private masterService:MasterService,
               private route: ActivatedRoute, public navService: NavbarService,
               private loginService: LoginService, 
               @Inject(DOCUMENT) private document: any) {

                this.UserId = JSON.parse(localStorage.getItem('currentUser'))["userId"];
                this.role = JSON.parse(localStorage.getItem('currentUser'))["roles"];
                
                if (this.role === 'Student') {
                this.stService.GetStudentOldAdmissionNo(this.UserId).subscribe(st => {   
                  this.oldsu = st[0]['stAdmissionNo'];                  
                //  this.showSubmit = false;
                 });
                }
                // else {
                //   this.showSubmit = true;
                // }

                this.stService.getMasterList();
                this.reportService.getMenu().subscribe(m => {
                  this.MenuList = m;
                  this.route.params.subscribe(params => {
                    this.id = params['id']; // (+) converts string 'id' to a number
                      let menu = this.MenuList.filter(m => (m.MenuName.toLowerCase() === this.id.toLowerCase()
                      && m.ParentName === "Reports"));
                      this.menuid = menu[0].MenuID;
                      this.reportService.getReportDetails(this.menuid).subscribe(rp => {
                        this.dropDownList = rp;
                        this.showSession=false;
                        this.showClass = false;
                        this.showSection = false;
                        this.showType = false;
                        this.showRegistration = false;
                        this.showNurseryRegistration =false;
                        this.showOnlineRegistration =false;
                        this.showAdmissionForm =false;
                        this.showAdmission = false;
                        this.showFeeReceipt = false;
                        this.showOldSuid = false;
                        this.showFromDate = false;
                        this.showToDate = false;
                        this.showEmp = false;
                        this.showRoute=false;
                        this.showFeeHead=false;
                        this.showStartDate=false;
                        // this.showSubmit = true;

                        if (this.role === 'Student') {
                          this.dropDownList.filter(m => {
                            if(this.menuid === 1){                             
                              this.showRegistration = true;
                              // this.showSubmit = false;                          
                            }else if(this.menuid === 2){                           
                              this.showAdmission = true;
                              // this.showSubmit = false;                         
                            }else if(this.menuid === 3){  
                              this.showSession=true;
                              this.showClass = true;
                               this.showSection = true;
                              this.showType = true;
                              // this.showSubmit = false;
                             }else if(this.menuid === 4){  
                              this.showSession=true;
                              this.showClass = true;  
                              // this.showSubmit = false;                          
                             }else if(this.menuid === 5){                             
                              this.showSession=true;
                              // this.showSubmit = false;
                              this.showFeeReceipt = true;                                                      
                            }else if(this.menuid === 6){                              
                               this.showSession=true;  
                               // this.showSubmit = false;                          
                             }else if(this.menuid === 7){  
                               this.showSession=true;
                               this.showClass = true;
                               // this.showSubmit = false;
                             }else if(this.menuid === 8){  
                              this.showSession = true;
                              this.showClass = true;
                              this.showSection = true;
                              // this.showSubmit = false;
                            }else if(this.menuid === 9){  
                               this.showSession=true;
                               this.showOldSuid = false;
                               // this.showSubmit = false;                               
                             }else if(this.menuid === 10){
                              this.showSession=true;
                              this.showFromDate = true;
                              this.showToDate = true;  
                              this.showOldSuid = true;
                              // this.showSubmit = false;                          
                            }else if(this.menuid === 11){                              
                              this.showSession=true;
                              // this.showSubmit = false;                            
                            }else if(this.menuid === 12){  
                              this.showSession=true;
                              this.showClass = true;
                             // this.showSubmit = false;
                            }else if(this.menuid === 13){  
                             this.showSession = true;
                             this.showClass = true;
                             this.showSection = true;
                            // this.showSubmit = false;
                           }else if(this.menuid === 14){  
                            this.showSession=true;
                            this.showOldSuid = false;
                           // this.showSubmit = false;                            
                           }else if(this.menuid === 15){  
                            this.showSession=true;
                            this.showOldSuid = true;
                           // this.showSubmit = false;
                           }else if(this.menuid === 16){  
                            this.showSession=true;
                            this.showOldSuid = true;
                           // this.showSubmit = false;
                           }else if(this.menuid === 17){  
                            this.showNurseryRegistration = true;
                           // this.showSubmit = false; 
                           }else if(this.menuid === 18){  
                            this.showOnlineRegistration = true;
                           // this.showSubmit = false; 
                           }else if(this.menuid === 19){  
                            this.showAdmissionForm = true;
                           // this.showSubmit = false; 
                           }else if(this.menuid === 20){  
                            this.showSession = true;
                            this.showClass = true;
                             this.showSection = true;
                            this.showType = true;
                           // this.showSubmit = false;
                           }else if(this.menuid === 21){  
                            this.showSession = true;
                           // this.showSubmit = false;            
                           }else if(this.menuid === 22){                           
                            this.showAdmission = true;
                           // this.showSubmit = false;                         
                          }else if(this.menuid === 23){                           
                            this.showAdmission = true;   
                           // this.showSubmit = false;                      
                          }else if(this.menuid === 24){  
                            this.showSession = true;                                                     
                            this.showAdmission = false; 
                            // this.showSubmit = false; 
                          }else if(this.menuid === 25){    
                            this.showSession = true;                                                   
                            this.showAdmission = false;    
                            // this.showSubmit = false;                     
                          }else if(this.menuid === 26){  
                            this.showSession = true;
                            this.showClass = true;
                            this.showSection = true;
                           // this.showSubmit = false;
                          }
                         });
                        } else {
                        this.dropDownList.filter(m => {
                          // if(m.SQLParameter == "sessionId"){
                          //   this.showSession=true;
                          // }else if(m.SQLParameter == "classId"){
                          //   this.showClass = true;
                          // }else if(m.SQLParameter == "sectionId"){
                          //   this.showSection = true;
                          // }else if(m.SQLParameter == "type"){
                          //   this.showType = true;
                          // // }else if(m.SQLParameter == "RegistrationNo"){
                          // }else if(this.menuid == 1){ 
                          if(this.menuid === 1){                             
                            this.showRegistration = true;                          
                          }else if(this.menuid === 2){                           
                            this.showAdmission = true;                         
                          }else if(this.menuid === 3){  
                            this.showSession=true;
                            this.showClass = true;
                             this.showSection = true;
                            this.showType = true;
                           }else if(this.menuid === 4){  
                            this.showSession=true;
                            this.showClass = true;                            
                           }else if(this.menuid === 5){                             
                            this.showSession=true;
                            this.showFeeReceipt = true;                                                      
                          }else if(this.menuid === 6){                              
                             this.showSession=true;                            
                           }else if(this.menuid === 7){  
                             this.showSession=true;
                             this.showClass = true;
                           }else if(this.menuid === 8){  
                            this.showSession = true;
                            this.showClass = true;
                            this.showSection = true;
                          }else if(this.menuid === 9){  
                             this.showSession=true;
                             this.showOldSuid = true;                             
                           }else if(this.menuid === 10){
                            this.showSession=true;
                            this.showFromDate = true;
                            this.showToDate = true;  
                            this.showOldSuid = true;                          
                          }else if(this.menuid === 11){                              
                            this.showSession=true;                            
                          }else if(this.menuid === 12){  
                            this.showSession=true;
                            this.showClass = true;
                          }else if(this.menuid === 13){  
                           this.showSession = true;
                           this.showClass = true;
                           this.showSection = true;
                         }else if(this.menuid === 14){  
                          this.showSession=true;
                          this.showOldSuid = true;                          
                         }else if(this.menuid === 15){  
                          this.showSession=true;
                          this.showOldSuid = true;
                         }else if(this.menuid === 16){  
                          this.showSession=true;
                          this.showOldSuid = true;
                         }else if(this.menuid === 17){  
                          this.showNurseryRegistration = true; 
                         }else if(this.menuid === 18){  
                          this.showOnlineRegistration = true; 
                         }else if(this.menuid === 19){  
                          this.showAdmissionForm = true; 
                         }else if(this.menuid === 20){  
                          this.showSession = true;
                          this.showClass = true;
                           this.showSection = true;
                          this.showType = true;
                         }else if(this.menuid === 21){  
                          this.showSession = true;            
                         }else if(this.menuid === 22){                           
                          this.showAdmission = true;                         
                        }else if(this.menuid === 23){                           
                          this.showAdmission = true;                         
                        }else if(this.menuid === 26){  
                          this.showSession = true;
                          this.showClass = true;
                          this.showSection = true;
                         // this.showSubmit = false;
                        }else if(this.menuid === 27){  
                          // this.showSession=true;
                          //   this.showClass = true;
                          //    this.showSection = true;
                          //   this.showType = true;
                        }else if(this.menuid === 28){  
                          this.showFromDate = true;
                          this.showToDate = true;  
                          this.showEmp=true
                          // this.showSession=true;
                          // this.showClass = true;
                          //  this.showSection = true;
                          // this.showType = true;
                        }else if(this.menuid === 29){  
                          this.showSession=true;
                          this.showClass = true;
                           this.showSection = true;
                          // this.showType = true;
                        }else if(this.menuid === 30){                           
                          this.showAdmission = true;                         
                        }else if(this.menuid === 31){  
                          this.showFromDate = true;
                          this.showToDate = true;                           
                        }else if(this.menuid === 32){  
                          this.showFromDate = true;
                          this.showToDate = true;                           
                        }else if(this.menuid === 33){  
                          this.showFromDate = true;
                          this.showToDate = true; 
                          this.showSession=true;
                          this.showClass = true;
                           this.showSection = true;                          
                        }else if(this.menuid === 34){  
                          // this.showFromDate = true; 
                          this.showStartDate =true
                          //this.showToDate = true;                         
                          this.showSession=true;
                          this.showRoute=true;                                                  
                        }else if(this.menuid === 35){                         
                          this.showSession=true;
                          this.showFeeHead=true;                                                 
                        }else if(this.menuid === 36){  
                          this.showFromDate = true;
                          this.showToDate = true; 
                          this.showSession=true;
                          this.showClass = true;
                           this.showSection = true;  
                           this.showAdmission = true;                          
                        }else if(this.menuid === 37){  
                          this.showFromDate = true;
                          this.showToDate = true; 
                          this.showSession=true;
                          this.showClass = true;
                           this.showSection = true;                          
                        }else if(this.menuid === 38){  
                          // this.showFromDate = true; 
                          this.showStartDate =true
                          //this.showToDate = true;                         
                         // this.showSession=true;
                         // this.showRoute=true;                                                  
                        }else if(this.menuid === 39){  
                          this.showFromDate = true;
                          this.showToDate = true;
                        }else if(this.menuid === 40){
                          this.showSession=true;
                          this.showFromDate = true;
                          this.showToDate = true;  
                          this.showOldSuid = true;
                        }else if(this.menuid === 41){
                          this.showSession=true;
                          this.showFromDate = true;
                          this.showToDate = true;  
                          this.showOldSuid = true;
                        }else if(this.menuid === 42){
                          this.showSession=true;
                          this.showFromDate = true;
                          this.showToDate = true;                          
                        }    


                        });
                      }
                      });
                    });
                });


    this.reportsForm = this.formBuilder.group({      
      session: [''],
      class: [''],
      section: [''],
      admissionno: [''],
      registrationno:[''],
      nurseryregistrationno:[''],
      onlineregistrationno:[''],
      admisssionform:[''],
      oldsuid:[''],
      fromdate:[''],
      todate:[''],
      feereceiptno:[''],      
      type:[''],
      EmpId:[''],
      RouteId:[''],
      FeeId:[''],
      startdate:['']

    });
  }

  ngOnInit() {    
    // var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // const startyear = Number(this.datePipe.transform(firstDay, 'yyyy'));
    // const startMonth = Number(this.datePipe.transform(firstDay, 'MM'));
    // const startDay = Number(this.datePipe.transform(firstDay, 'dd'));
    // const endYear = Number(this.datePipe.transform(lastDay, 'yyyy'));
    // const endMonth = Number(this.datePipe.transform(lastDay, 'MM'));
    // const endDay = Number(this.datePipe.transform(lastDay, 'dd'));

   
    

    this.domain = this.document.location.hostname;
    console.log(this.domain);
    // this.UserId=JSON.parse(localStorage.getItem('currentUser'))["userId"];

    this.schid = this.schid = (<User>this.loginService.currentUserSubject.value ).schoolId;

    this.stService.masterList.subscribe(m => { this.staticList = m; });

    this.masterService.getMasterList().subscribe(m => {
      this.staticList = m;
    });

    this.masterService.getEmployeeList(this.schid).subscribe(lst => { this.EmployeeList = lst; });
    this.masterService.getBusRouteList().subscribe(lst => { this.busrouteList = lst; });
    this.masterService.getFeeHeadListBySchoolMasterPage().subscribe(lst => { this.schfeeheadList = lst; });
   // this.masterService.getSessionList().subscribe(lst => { this.sessionList = lst});
   this.masterService.getSessionList().subscribe(lst => { this.sessionList = lst;
    let defaultSession = this.sessionList.filter(sm => sm.sm_CurrentSession === true);
    if(defaultSession.length === 0) {
      this.reportsForm.controls['session'].setValue(0);
    } else {
      this.reportsForm.controls['session'].setValue(defaultSession[0].sm_Sessionid);
      this.selectedSession = defaultSession[0].sm_Sessionid;
    }
  });
  }

  onChangeSelectSession($event){     
    let sessions = this.sessionList.filter(sl => sl.sm_Sessionid == $event.target.value);
    let startingDate = sessions[0]['sm_SessionStartingDate'];
    // if ((this.showFromDate === true) && (this.showToDate === true)){
    // this.reportsForm.controls['fromdate'].setValue(this.datePipe.transform(sessions[0]['sm_SessionStartingDate'],'YYYY-MM-DD'));
    // this.reportsForm.controls['todate'].setValue(this.datePipe.transform(sessions[0]['sm_SessionEndingDate'],'YYYY-MM-DD'));
    // }else {
    //   this.reportsForm.controls['fromdate'].setValue(null);
    //   this.reportsForm.controls['todate'].setValue(null);
    // }
    // startingDate = new Date(startingDate);
    // currentElement.sm_SessionStartingDate = this.dateFormat.transform(currentElement.sm_SessionStartingDate,'YYYY-MM-DD');
    // currentElement.sm_SessionEndingDate = this.dateFormat.transform(currentElement.sm_SessionEndingDate,'YYYY-MM-DD');
    
      if ((this.showFromDate === true) && (this.showToDate === true)){
        let FromDate = sessions[0]['sm_SessionStartingDate'];
        if(FromDate !=null){        
        let startDate = moment(FromDate).format('YYYY-MM-DD');
        const dt = new Date(startDate);
    this.reportsForm.controls['fromdate'].setValue({ year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() });
       // this.reportsForm.controls['fromdate'].setValue(startDate);  
             
        }
        let ToDate = sessions[0]['sm_SessionEndingDate'];
        if(ToDate !=null){
       
        let endDate = moment(ToDate).format('YYYY-MM-DD');        
        //this.reportsForm.controls['todate'].setValue(endDate);
        const dt1 = new Date(endDate);
    this.reportsForm.controls['todate'].setValue({ year: dt1.getFullYear(), month: dt1.getMonth() + 1, day: dt1.getDate() });
        }
    } else {
    this.reportsForm.controls['fromdate'].setValue(null);
    this.reportsForm.controls['todate'].setValue(null);
    }
  
  }


  resetForm() {
    this.reportsForm.reset();
  }
 
  onSubmit() {

    if(this.reportsForm.controls['registrationno'].value === "undefined") {
      this.reportsForm.controls['registrationno'].setValue(0);
    }
    if(this.reportsForm.controls['nurseryregistrationno'].value === "undefined") {
      this.reportsForm.controls['nurseryregistrationno'].setValue(0);
    }
    if(this.reportsForm.controls['onlineregistrationno'].value === "undefined") {
      this.reportsForm.controls['onlineregistrationno'].setValue(0);
    }
    if(this.reportsForm.controls['admisssionform'].value === "undefined") {
      this.reportsForm.controls['admisssionform'].setValue(0);
    }

    if (this.reportsForm.controls['admissionno'].value === "undefined"){
      this.reportsForm.controls['admissionno'].setValue(0);
    }
    if (this.reportsForm.controls['feereceiptno'].value === "undefined"){
      this.reportsForm.controls['feereceiptno'].setValue(0);
    }
    if (this.reportsForm.controls['oldsuid'].value === "undefined"){
      this.reportsForm.controls['oldsuid'].setValue(0);
    }
    if (this.reportsForm.controls['type'].value === "undefined"){
      this.reportsForm.controls['type'].setValue(0);
    }
    
    if ((this.showFromDate === true) && (this.showToDate === true)){
        let FromDate = this.reportsForm.controls['fromdate'].value;
        if(FromDate !=null){
        let startDate = FromDate.year + '/' + FromDate.month + '/' + FromDate.day;
        this.reportsForm.controls['fromdate'].setValue(startDate);
        }
        let ToDate = this.reportsForm.controls['todate'].value;
        if(ToDate !=null){
        let endDate = ToDate.year + '/' + ToDate.month + '/' + ToDate.day;
        this.reportsForm.controls['todate'].setValue(endDate);
        }
    } else {
      this.reportsForm.controls['fromdate'].setValue(null);
      this.reportsForm.controls['todate'].setValue(null);
    }

    if (this.showStartDate === true){
      let StartDate = this.reportsForm.controls['startdate'].value;
      if(StartDate !=null){
      let startDate = StartDate.year + '/' + StartDate.month + '/' + StartDate.day;
      this.reportsForm.controls['startdate'].setValue(startDate);
      }      
  } else {
    this.reportsForm.controls['startdate'].setValue(null);    
  }

    // this.employeeid=this.reportsForm.controls['EmpId'].value?this.reportsForm.controls['EmpId'].value:0;
    
const data = { 
                rptid:this.menuid,
                sessionid: this.reportsForm.controls['session'].value?this.reportsForm.controls['session'].value:0,
                classid: this.reportsForm.controls['class'].value?this.reportsForm.controls['class'].value:0,
                sec: this.reportsForm.controls['section'].value?this.reportsForm.controls['section'].value:0,
                type: this.reportsForm.controls['type'].value?this.reportsForm.controls['type'].value:0,
                registrationno: this.reportsForm.controls['registrationno'].value?this.reportsForm.controls['registrationno'].value:"",
                nurseryregistrationno: this.reportsForm.controls['nurseryregistrationno'].value?this.reportsForm.controls['nurseryregistrationno'].value:"",
                onlineregistrationno: this.reportsForm.controls['onlineregistrationno'].value?this.reportsForm.controls['onlineregistrationno'].value:"",
                admisssionform: this.reportsForm.controls['admisssionform'].value?this.reportsForm.controls['admisssionform'].value:"",
                admissionno: this.reportsForm.controls['admissionno'].value?this.reportsForm.controls['admissionno'].value:"",
                feereceiptno: this.reportsForm.controls['feereceiptno'].value?this.reportsForm.controls['feereceiptno'].value:"",
                oldsuid: this.reportsForm.controls['oldsuid'].value?this.reportsForm.controls['oldsuid'].value:"",
                fromdate: this.reportsForm.controls['fromdate'].value?this.reportsForm.controls['fromdate'].value:null,
                todate: this.reportsForm.controls['todate'].value?this.reportsForm.controls['todate'].value:null, 
                startdate: this.reportsForm.controls['startdate'].value?this.reportsForm.controls['startdate'].value:null,
                empId: this.reportsForm.controls['EmpId'].value?this.reportsForm.controls['EmpId'].value:0,
                routeId: this.reportsForm.controls['RouteId'].value?this.reportsForm.controls['RouteId'].value:0,
                feeId: this.reportsForm.controls['FeeId'].value?this.reportsForm.controls['FeeId'].value:0,              
                oldsu : this.oldsu,
                schid: this.schid,
                
                domain:this.domain,
                UserId:this.UserId
              };

            
    this.reportService.generatePDF(data).subscribe((res:any)=> {
      if (res) {
        let pdfWindow = window.open("");
    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res) + "'></iframe>");
      } else {
      }
    }); 

  }

  
}
