import { DecimalPipe } from '@angular/common'

export class StudentFeePay {
     schId : number;     
     suId : string;     
     sesId : string;     
     amount: string;
     custMobile : string;
     custEmail : string;
     AdNo: string;
     userId : string;
}
