import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UpdateFeeReceipt } from '../../shared/model/UpdateFeeReceipt.model';
import { UpdateFeeReceiptStatus } from '../../shared/model/UpdateFeeReceiptStatus.model';
import { StudentService } from '../../shared/services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateFormatPipe } from '../../shared/pipes/dateformat.pipe';
import { DatePipe } from '@angular/common';
import { MasterService } from '../../shared/services/master.service';
import { NgWizardService, NgWizardConfig, THEME, StepChangedArgs, TOOLBAR_BUTTON_POSITION, TOOLBAR_POSITION } from 'ng-wizard';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../shared/services/login.service';
import { User } from '../../shared/model/user.model';
import { FeeReceiptService } from '../../shared/services/feereceipt.service';
import { DOCUMENT } from '@angular/common';
import { StudentFeePayStatus} from '../../shared/model/student-fee-pay-status.model';
@Component({
  selector: 'app-fee-receipt-payment-status',
  templateUrl: './fee-receipt-payment-status.component.html',
  styleUrls: ['./fee-receipt-payment-status.component.scss'],
  providers: [DateFormatPipe, DatePipe, UpdateFeeReceipt ]
})
export class FeeReceiptPaymentStatusComponent implements OnInit {

  public stOrgList: any = [];
  public stList: any = [];
  public orgList: any = [];
  public staticList: any = {};
  public txtFilter: any = '';
  public userFilter: any;
  public feeReceiptpaymentForm: FormGroup;
  public p: number = 1;
  public toDate: any;
  public fromDate: any;
  public paymentMode: any;
  public ClassId: any;
  public SessionId: any;
  public SectionId: any;
  public message: string = null;
  public stSection: any;
  public stClass: any;
  public PaymentMode: any;
  public UpdateModel: UpdateFeeReceipt;
  public FeModel: UpdateFeeReceiptStatus;
  public FeeList: any[];
  public Roles: any;
  public RoleId = 0;
  public domain: string = null;
  public userId:number;
  masterSelected: boolean;
  StudentFeePayStatus = new StudentFeePayStatus();
  public FeeReceiptList= [({
          fro_frid: 0,
          fro_message: '',
		      fro_statuscode: '',
				  fro_bankTxnId: '',
				  fro_atomTxnId: '',
				  fro_txnCompleteDate: '',
				  fro_Totpaidamt: 0,				  
				  fro_suid: 0,
				  fro_Sessionid: 0,
				  fro_merchTxnId: '',
				  fro_merchTxnDate: '',
				  fro_AdNo: '',
				  fro_userid: '',
				  su_suname: '',
          sd_sdname: '',
          cs_csname: '',
          sud_rollno: 0,
  
})]

  constructor(private router: Router, private stService: StudentService, private feereceiptService: FeeReceiptService,
    private formBuilder: FormBuilder, private toast: ToastrService, private datePipe: DatePipe, private loginService: LoginService,
    @Inject(DOCUMENT) private document: any) {
    this.UpdateModel = new UpdateFeeReceipt();
    this.UpdateModel.UpdateFeeReceiptStatus = [];
    this.FeModel = new UpdateFeeReceiptStatus();
    this.userId = (<User>this.loginService.currentUserSubject.value ).userId;
    this.stService.getMasterList();
    // this.loginService.currentUserSubject.subscribe(m => {
    //   debugger;
    //   this.Roles = m;
    //   if (this.Roles.roleid == 2 || this.Roles.roleid == 1) {
    //     this.RoleId = 1;
    //   }
    //   console.log(this.RoleId);
    // })

  }

  ngOnInit() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const startyear = Number(this.datePipe.transform(firstDay, 'yyyy'));
    const startMonth = Number(this.datePipe.transform(firstDay, 'MM'));
    const startDay = Number(this.datePipe.transform(firstDay, 'dd'));
    const endYear = Number(this.datePipe.transform(lastDay, 'yyyy'));
    const endMonth = Number(this.datePipe.transform(lastDay, 'MM'));
    const endDay = Number(this.datePipe.transform(lastDay, 'dd'));
    this.feeReceiptpaymentForm = this.formBuilder.group({
      // toDate: [{ year: endYear, month: endMonth, day: endDay }],
      // fromDate: [{ year: startyear, month: startMonth, day: startDay }],
      // SessionId:[''],
      // SectionId: [''],
      stSection: [''],
      stClass: ['']
   
    });

    this.stService.masterList.subscribe(m => {
      this.staticList = m;
      console.log(m);
    })
    this.loadFeeReceiptList();
  }

  loadFeeReceiptList() {
    this.stList = [];
    this.FeeReceiptList = [];
    this.FeeList = [];
    
    // var fromDate = this.feeReceiptpaymentForm.controls['fromDate'].value;
    // var toDate = this.feeReceiptpaymentForm.controls['toDate'].value;
    // var startDate = fromDate.year + '/' + fromDate.month + '/' + fromDate.day;
    // var endDate = toDate.year + '/' + toDate.month + '/' + toDate.day;
    this.stService.GetStudentPaymentStatusList().
      subscribe(m => {      
        this.FeeList = m;
        for (let data of m) {
          this.FeeReceiptList.push({
            fro_frid: data.fro_frid,
            fro_message: data.fro_message,
            fro_statuscode: data.fro_statuscode,
            fro_bankTxnId: data.fro_bankTxnId,
            fro_atomTxnId: data.fro_atomTxnId,
            fro_txnCompleteDate: data.fro_txnCompleteDate,
            fro_Totpaidamt: data.fro_Totpaidamt,				  
            fro_suid:data.fro_suid,
            fro_Sessionid: data.fro_Sessionid,
            fro_merchTxnId: data.fro_merchTxnId,
            fro_merchTxnDate: data.fro_merchTxnDate,
            fro_AdNo: data.fro_AdNo,
            fro_userid: data.fro_userid,
            su_suname: data.su_suname,
            sd_sdname: data.sd_sdname,
            cs_csname: data.cs_csname,
            sud_rollno:data.sud_rollno                  
          });
        }
       // this.FeeReceiptList.shift();        
        this.stList = this.FeeReceiptList;
        console.log(this.FeeReceiptList);
      });
  //  [disabled] = "(( RoleId!=1) && (itm.fr_chqbounced||itm.fr_chqcleared||itm.fr_active))"
  }
  // checkBouncedBox(itm, i, value) {    
  //    if (itm.fr_active  || itm.fr_chqcleared) { 
  //    itm.fr_active = false;
  //    itm.fr_chqcleared = false;

  //   }    
  //   itm.IsChecked = false;
  //   itm.fr_chqbounced = value.target.checked;
  //   itm.fr_active = false;
  //   this.stList[i] = itm;   

  // }
  // checkCancelBox(itm, i, value) {
  //   if (itm.fr_chqbounced || itm.fr_chqcleared) {
  //     itm.fr_chqbounced = false;
  //     itm.fr_chqcleared = false;
  //   }
    
  //   itm.fr_active = value.target.checked;
  //   itm.IsChecked = false;
  //   this.stList[i] = itm;

  // }
 
  // checkClearedBox(itm, i, value) {
  //   if (itm.fr_chqbounced || itm.fr_active) {
  //     itm.fr_chqbounced = false;
  //    // itm.fr_active = false;
  //   }
  //   itm.IsChecked = false;
  //   itm.fr_chqcleared = value.target.checked;
  //   this.stList[i] = itm;
  // }
  searchByClass() {
    this.stList = this.FeeList;
    this.stList = this.stList.filter(obj => obj.sd_sdname == this.feeReceiptpaymentForm.controls['stClass'].value);

  }

  searchBySection() {
    this.stList = this.FeeList;
    this.stList = this.stList.filter(obj => obj.cs_csname == this.feeReceiptpaymentForm.controls['stSection'].value );

  }
  // searchByPayment() {
  //   this.stList = this.FeeList;
  //   this.stList = this.stList.filter(obj => obj.fr_modeofpayment == this.feeReceiptpaymentForm.controls['PaymentMode'].value);

  // }

  Save(itm) {
    this.StudentFeePayStatus.schId  = (this.loginService.currentUserSubject.value ).schoolId;    
    this.StudentFeePayStatus.suId  = itm.fro_suid;   
    this.StudentFeePayStatus.sesId = itm.fro_Sessionid;   
    this.StudentFeePayStatus.amount  = itm.fro_Totpaidamt;
    this.StudentFeePayStatus.merchTxnId  = itm.fro_merchTxnId;
    this.StudentFeePayStatus.merchTxnDate  = itm.fro_merchTxnDate;  
    this.StudentFeePayStatus.AdNo  = itm.fro_AdNo;  
    this.StudentFeePayStatus.userId  = itm.fro_userid;
    this.StudentFeePayStatus.message=""; 
    this.StudentFeePayStatus.statusCode="";
    this.StudentFeePayStatus.bankTxnId=""; 
    this.StudentFeePayStatus.atomTxnId="";
    this.StudentFeePayStatus.txnCompleteDate="";
    this.feereceiptService.ManageStatus(this.StudentFeePayStatus).subscribe(result => {
        console.log(result);      
        // this.merchTxnId= result.Item4;
        // this.merchTxnDate= result.Item5;    
        // if(result.Item1>0){
        //   this.SavefeeReceipt(this.merchTxnId,this.merchTxnDate);
        // }
        if(result >0){
        this.message = result.message;
        this.toast.success(this.message);   
        }else{
          this.toast.success(result.message, "Check Fee Receipt against Transaction or verify with Bank Dash Board");
        }
        //this.toast.success(result.message, "Success");
       // window.location.reload();
        });  
   
  
  }
//  PrintfeeReceipt(itm) {
//   this.domain = this.document.location.hostname;  
//   // for (let itm of this.stList) {
//   //   if (itm.fr_active === true) {  
//   //     let f= itm.fr_frid;
//   //     let st = itm.su_oldsuid;
//       this.feereceiptService.getFeeReceiptStatusPDF(itm.fr_frid, itm.su_oldsuid,
//         this.feeReceiptpaymentForm.controls['SessionId'].value, this.domain, this.userId).subscribe((response: any) => {
//           const pdfWindow = window.open("")
//           pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + 
//           encodeURI(response) + "'></iframe>");
//            });
//   //   }
//   // }
//  }
}

