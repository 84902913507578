import { DecimalPipe } from '@angular/common'

export class StudentFeePayStatus {
     schId : number;     
     suId : string;     
     sesId : string;     
     amount: string;
     merchTxnId : string;
     merchTxnDate : string;
     AdNo: string;
     userId : string;

     message: string; 
     statusCode: string; 
     bankTxnId: string; 
     atomTxnId: string; 
     txnCompleteDate: string; 

    
}
