import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
// import { JsonPipe } from '@angular/common';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { User } from '../model/user.model';
// import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public stid = 0;
  public frid = null;
  public schid = 0;
  public role: string;
  // public schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
  constructor(private loginService: LoginService, private httpClient: HttpClient, public spinService: SpinnerVisibilityService) {
    this.schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
  }


  /* getPDF(studentid, domainName){
    let SchoolId =  this.schid;
    return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentProfilePDF?mode=1&qry='
    +studentid+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
  } */

  getMenu(){
    this.role = this.loginService.currentUserValue.roles;
    if (this.role === 'Student') {
      return this.httpClient.get(apiUrl + '/api/user/GetstudentMenu');
  } else {
    return this.httpClient.get(apiUrl + '/api/user/GetMenu');
  }
  }
 
  getReportDetails(menuid){
    return this.httpClient.get(apiUrl+'/api/SystemSetting/GetReportDetailsList?rptId='+menuid,httpOptions); 
  }

  generatePDF(data){
    data.registrationno = data.registrationno ? data.registrationno : 0;
    data.nurseryregistrationno = data.nurseryregistrationno ? data.nurseryregistrationno : 0;
    data.onlineregistrationno = data.onlineregistrationno ? data.onlineregistrationno : 0;
    data.admisssionform = data.admisssionform ? data.admisssionform : 0;
    data.admissionno = data.admissionno ? data.admissionno : 0;
    data.feereceiptno = data.feereceiptno ? data.feereceiptno : 0;

    if (this.role === 'Student') {
      data.oldsuid = data.oldsu ? data.oldsu : 0;
    } else { 
      data.oldsuid = data.oldsuid ? data.oldsuid : 0;
    }
    data.type = data.type ? data.type : 0;

     if(data.rptid == 1) {
      return this.httpClient.post<Blob>(apiUrl + '/api/Setting/GeneratePDF?mode=1&domain=' + data.domain
      + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId='
      + data.classid + '&sec=' + data.sec + '&qry=' + data.registrationno +  '&oldsuid=' +
      data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
      + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
       {  responseType: 'blob' as 'json', httpOptions });
    }
    if(data.rptid == 2) {
     return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
     + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
     + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
     data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
     + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
     {  responseType: 'blob' as 'json', httpOptions });

    //  return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentProfilePDF?mode=1&qry='
    //  + data.admissionno + '&schId=' + this.schid + '&domain=' + data.domain,
    //   {  responseType: 'blob' as 'json', httpOptions });
    }
    if(data.rptid == 3) {  
      return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=' + data.type + '&domain=' + data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
        {  responseType: 'blob' as 'json', httpOptions });
    }
    if(data.rptid == 4) {  
      return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=' + data.type + '&domain=' + data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
        {  responseType: 'blob' as 'json', httpOptions });
    }
     if(data.rptid == 5) {

       return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
      + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
      + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
      data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
      + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
      {  responseType: 'blob' as 'json', httpOptions });

      // return this.httpClient.post<Blob>(apiUrl + '/api/Fee/PrintFeeReceiptPDF?qry=' + data.feereceiptno
      // + '&oldsuid=' + data.admissionno + '&schId=' + this.schid + '&sessionid=' + data.sessionid
      // + '&domain=' + data.domain + '&userid=' + data.UserId,
      //  {  responseType: 'blob' as 'json', httpOptions });
      
     }
     if(data.rptid == 6) {  
    return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
      + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
      + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
      data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
      + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
      {  responseType: 'blob' as 'json', httpOptions });
     }
     if(data.rptid == 7) {
      return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
         {  responseType: 'blob' as 'json', httpOptions });      
      }
      if(data.rptid == 8) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
          {  responseType: 'blob' as 'json', httpOptions });      
      }
      if(data.rptid == 9) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
          {  responseType: 'blob' as 'json', httpOptions });      
      }
      if(data.rptid == 10) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 11) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 12) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 13) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 14) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 15) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 16) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 17) {
        return this.httpClient.post<Blob>(apiUrl + '/api/Setting/GeneratePDF?mode=1&domain=' + data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId='
        + data.classid + '&sec=' + data.sec + '&qry=' + data.nurseryregistrationno +  '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
         {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 18) {
        return this.httpClient.post<Blob>(apiUrl + '/api/Setting/GeneratePDF?mode=1&domain=' + data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId='
        + data.classid + '&sec=' + data.sec + '&qry=' + data.onlineregistrationno +  '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
         {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 19) {
        return this.httpClient.post<Blob>(apiUrl + '/api/Setting/GeneratePDF?mode=1&domain=' + data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId='
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admisssionform +  '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
         {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 20) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=' + data.type + '&domain=' + data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
       if(data.rptid == 21) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=' + data.type + '&domain=' + data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 22) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 23) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 24) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 25) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 26) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 27) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 28) {  
        if(data.empId==0){
          return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
            + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
            + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
            data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
            + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
            {  responseType: 'blob' as 'json', httpOptions });
        }
        else{
          return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=2&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
        }
      }
      if(data.rptid == 29) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=14&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });          
      }
      if(data.rptid == 30) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 31) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 32) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 33) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 34) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.startdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 35) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 36) {
        if(data.admissionno==0){
          return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
          {  responseType: 'blob' as 'json', httpOptions }); 
        }else {
          return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=2&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
          {  responseType: 'blob' as 'json', httpOptions }); 
        }     
       }
       if(data.rptid == 37) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=3&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 38) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.startdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 39) {
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
        + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
        + data.classid + '&sec=' + data.sec + '&qry=' + data.admissionno + '&oldsuid=' +
        data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
        + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId, 
        {  responseType: 'blob' as 'json', httpOptions }); 
      
       }
       if(data.rptid == 40) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 41) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
      if(data.rptid == 42) {  
        return this.httpClient.post<Blob>(apiUrl +'/api/Setting/GeneratePDF?mode=1&domain='+ data.domain
          + '&rptId=' + data.rptid + '&schoolid=' + data.schid + '&sessionId=' + data.sessionid + '&classId=' 
          + data.classid + '&sec=' + data.sec + '&qry=' + data.feereceiptno + '&oldsuid=' +
          data.oldsuid + '&userid=' + data.UserId + '&fromdate=' + data.fromdate + '&todate=' + data.todate
          + '&empId=' + data.empId+ '&routeId=' + data.routeId+ '&feeId=' + data.feeId,
          {  responseType: 'blob' as 'json', httpOptions });
      }
  }


}
