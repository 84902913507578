import { DecimalPipe } from '@angular/common'

export class StudentFeeResponseDetailsByOnline {
     // encData : string;   
     message: string; 
     statusCode: string; 
     bankTxnId: string; 
     atomTxnId: string; 
     txnCompleteDate: string; 
     amount: string; 
     schId: string; 
     suId: string; 
     sesId: string; 
     Signature: string; 
     AdNo: string; 
     userId: string; 
     
}
