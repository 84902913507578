import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './login/login.component';
import { RegistrationLoginAllComponent } from './registrationloginall/registrationloginall.component';
import { NurseryLoginAllComponent } from './nurseryloginall/nurseryloginall.component';
import { RamjasNurseryLoginComponent } from './ramjasnurserylogin/ramjasnurserylogin.component';
import { RamjasRegistrationLoginComponent } from './ramjasregistrationlogin/ramjasregistrationlogin.component';
import { DPSKaluahiRegistrationLoginComponent } from './dpskaluahiregistrationlogin/dpskaluahiregistrationlogin.component';
import { BlueBellRegistrationLoginComponent } from './bluebellregistrationlogin/bluebellregistrationlogin.component';
import { ForgotpwdComponent } from './views/forgot/forgotpwd.component';
import { StudentForgotpwdComponent } from './studentforgot/studentforgotpwd.component';
import { StudentlistComponent } from './views/student/studentlist.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { RegistrationlistComponent } from './views/registration/registrationlist.component';
import { ReportsComponent } from './views/reports/reports.component';
import { FeeReceiptComponent} from './views/fee-receipt/fee-receipt.component';
import { FeeReceiptOnlineComponent} from './views/fee-receipt-online/fee-receipt-online.component';
import { FeeReceiptStatusComponent} from './views/fee-receipt-status/fee-receipt-status.component';
import { UpdateStudentComponent } from './views/updatestudent/updatestudent.component';
import { UpdatePrevAmtComponent } from './views/updateprevamt/updateprevamt.component';
import { UpdateStudentMonthFeeComponent } from './views/updatetransport/updatestudentmonthfee.component';
import { PendingStudentlistComponent } from './views/pendingstudentlist/pendingstudentlist.component';
import { GeneralSMSComponent } from './views/generalsms/generalsms.component';
import { GeneralSMSMAILComponent } from './views/generalsmsmail/generalsmsmail.component';
import { StudentAttendencelistComponent } from './views/studentattendence/studentattendencelist.component';
import { StudentattendenceComponent } from './views/studentattendence/studentattendence.component';
// import { OnlineRegistrationComponent } from './views/nurseryonlineregistration/onlineregistration.component';
import { RegistrationlistPointWiseComponent } from './views/registrationlistpointwise/registrationlistpointwise.component';
import { RegistrationlistExportComponent } from './views/registrationlistexport/registrationlistexport.component';
import { RegistrationSMSComponent } from './views/registrationsms/registrationsms.component';
import { StudentUserlistComponent } from './views/studentuserlist/studentuserlist.component';
import { UpdateStudentSelfComponent } from './views/updatestudentself/updatestudentself.component';
import { StudentFeeReceiptComponent} from './views/student-fee-receipt/student-fee-receipt.component';
import { SubSubjectComponent } from './views/subsubject/subsubject.component';
import { EmployeeAttendencelistComponent } from './views/employeeattendence/employeeattendencelist.component';
import { EmployeeattendenceComponent } from './views/employeeattendence/employeeattendence.component';
import { UserattendenceComponent } from './views/userattendence/userattendence.component';
import { PrintStudentComponent } from './views/printstudent/printstudent.component';
import { StudentExportComponent } from './views/studentexport/studentexport.component';
import { PrintExam1To2ResultComponent } from './views/printexam1To2result/printexam1To2result.component';
import { PrintExam3To10ResultComponent } from './views/printexam3To10result/printexam3To10result.component';
import { PrintExam11To12ResultComponent } from './views/printexam11To12result/printexam11To12result.component';
import { StudentExportConsolidatedReportCardComponent } from './views/studentexportconsolidatedreportcard/studentexportconsolidatedreportcard.component';
import { StudentExportAverageReportCardComponent } from './views/studentexportaveragereportcard/studentexportaveragereportcard.component';
import { PrintAdmitCardComponent } from './views/printadmitcard/printadmitcard.component';
import { RegistrationfeelistComponent } from './views/registrationfeelist/registrationfeelist.component';

// import { AtompayComponent} from './views/atompay/atompay.component';
import { AtomresponseComponent} from './views/atomresponse/atomresponse.component';
import { AtomsuccessresponseComponent} from './views/atomsuccessresponse/atomsuccessresponse.component';
import { FeeReceiptPaymentStatusComponent} from './views/fee-receipt-payment-status/fee-receipt-payment-status.component';

export const routes: Routes = [
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  // {
  //   path: 'dashboardregistration',
  //   redirectTo: 'dashboardregistration',
  //   pathMatch: 'full',
  // },

  // {
  //   path: 'fee-receipt',
  //   component: FeeReceiptComponent,
  //   pathMatch: 'full',
  // },
  { path: 'nurseryonlineregistration',
  loadChildren: () => import('./views/nurseryonlineregistration/onlineregistration.module').then(m => m.OnlineRegistrationModule)
  },

  { path: 'onlineregistration',
  loadChildren: () => import('./views/onlineregistration/onlineregistration.module').then(m => m.OnlineRegistrationModule)
  },

  {
    path: 'login',
    redirectTo: '',
    pathMatch: 'full',
  },
   {
    path: 'nurseryloginall',
    component:NurseryLoginAllComponent,
    pathMatch: 'full',
  },
  {
    path: 'registrationloginall',
    component:RegistrationLoginAllComponent,
    pathMatch: 'full',
  },
  {
    path: 'ramjasnurserylogin',
    component:RamjasNurseryLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'ramjasregistrationlogin',
    component:RamjasRegistrationLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'dpskaluahiregistrationlogin',
    component:DPSKaluahiRegistrationLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'bluebellregistrationlogin',
    component:BlueBellRegistrationLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'studentforgot',
    component:StudentForgotpwdComponent,
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      { path: 'master', 
        loadChildren: () => import('./views/master/master.module').then(m => m.MasterModule)
      },
      { path: 'hr', 
        loadChildren: () => import('./views/hr/hr.module').then(m => m.HRModule)
      },
      { path: 'accounts', 
        loadChildren: () => import('./views/accounts/accounts.module').then(m => m.AccountsModule)
      },
      { path: 'inventory', 
        loadChildren: () => import('./views/inventory/inventory.module').then(m => m.InventoryModule)
      },
      { path: 'purchase', 
        loadChildren: () => import('./views/purchase/purchase.module').then(m => m.PurchaseModule)
      },
      { path: 'sale', 
        loadChildren: () => import('./views/sale/sale.module').then(m => m.SaleModule)
      },
      { path: 'registration', 
        loadChildren: () => import('./views/registration/registration.module').then(m => m.RegistrationModule)
      },
      { path: 'registration/:id', 
        loadChildren: () => import('./views/registration/registration.module').then(m => m.RegistrationModule)
      },

      { path: 'registrationlist', component: RegistrationlistComponent },

      { path: 'student', 
        loadChildren: ()=>import('./views/student/student.module').then(m=>m.StudentModule) 
      },
      { path: 'student/:id',
        loadChildren: ()=>import('./views/student/student.module').then(m=>m.StudentModule) 
      }, 
      { path: 'studentlist', component: StudentlistComponent },
      { path: 'reset', component: ChangePasswordComponent },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboardregistration',
        loadChildren: () => import('./views/dashboardregistration/dashboardregistration.module').then(m => m.DashboardRegistrationModule)
      },
      {
        path: 'dashboardclassteacher',
        loadChildren: () => import('./views/dashboardclassteacher/dashboardclassteacher.module').then(m => m.DashboardClassTeacherModule)
      },
      {
        path: 'dashboardteacher',
        loadChildren: () => import('./views/dashboardteacher/dashboardteacher.module').then(m => m.DashboardTeacherModule)
      },
      {
        path: 'dashboardstudent',
        loadChildren: () => import('./views/dashboardstudent/dashboardstudent.module').then(m => m.DashboardStudentModule)
      },
      { path: 'schoolregistration',
      loadChildren: () => import('./views/schoolregistration/onlineregistration.module').then(m => m.OnlineRegistrationModule)
      },
      { path: 'schoolregistration/:id', 
      loadChildren: () => import('./views/schoolregistration/onlineregistration.module').then(m => m.OnlineRegistrationModule)
      },
      { path: 'nurseryregistration',
      loadChildren: () => import('./views/nurseryregistration/onlineregistration.module').then(m => m.OnlineRegistrationModule)
      },

       { path: 'verifyregistration',
      loadChildren: () => import('./views/verifyregistration/onlineregistration.module').then(m => m.OnlineRegistrationModule)
      },

      { path: 'reports/:id', component: ReportsComponent },

      { path: 'updatestudent', component: UpdateStudentComponent },

      { path: 'updateprevamt', component: UpdatePrevAmtComponent },

      { path: 'updatetransport', component: UpdateStudentMonthFeeComponent },

      {
        path: 'fee-receipt',
        component: FeeReceiptComponent,
        pathMatch: 'full',
      },
      {
        path: 'fee-receipt-online',
        component: FeeReceiptOnlineComponent,
        pathMatch: 'full',
      },
      {
        path: 'fee-receipt-status',
        component: FeeReceiptStatusComponent,
        pathMatch: 'full',
      },
      
      { path: 'pendingstudentlist', component: PendingStudentlistComponent },
      { path: 'generalsms', component: GeneralSMSComponent },
      { path: 'generalsmsmail', component: GeneralSMSMAILComponent },
      { path: 'studentattendencelist', component: StudentAttendencelistComponent },
      { path: 'studentattendence', component: StudentattendenceComponent },
      { path: 'registrationlistpointwise', component: RegistrationlistPointWiseComponent },
      { path: 'registrationlistexport', component: RegistrationlistExportComponent },
      { path: 'registrationsms', component: RegistrationSMSComponent },
      { path: 'studentuserlist', component: StudentUserlistComponent },
      { path: 'forgot', component: ForgotpwdComponent },
      { path: 'updatestudentself', component: UpdateStudentSelfComponent },
      {
        path: 'student-fee-receipt',
        component: StudentFeeReceiptComponent,
        pathMatch: 'full',
      },
      { path: 'subsubject', component: SubSubjectComponent, pathMatch: 'prefix' },
      { path: 'employeeattendencelist', component: EmployeeAttendencelistComponent },
      { path: 'employeeattendence', component: EmployeeattendenceComponent },
      { path: 'userattendence', component: UserattendenceComponent, pathMatch: 'prefix' },
      { path: 'printstudent', component: PrintStudentComponent },
      { path: 'studentexport', component: StudentExportComponent },
      { path: 'printexam1To2result', component: PrintExam1To2ResultComponent },
      { path: 'printexam3To10result', component: PrintExam3To10ResultComponent },
      { path: 'printexam11To12result', component: PrintExam11To12ResultComponent },
      { path: 'studentexportconsolidatedreportcard', component: StudentExportConsolidatedReportCardComponent },
      { path: 'studentexportaveragereportcard', component: StudentExportAverageReportCardComponent },      
      { path: 'printadmitcard', component: PrintAdmitCardComponent },
      { path: 'registrationfeelist', component: RegistrationfeelistComponent },
      // { path: 'atompay', component: AtompayComponent },
      { path: 'atomresponse', component: AtomresponseComponent },
      {
        path: 'fee-receipt-payment-status',
        component: FeeReceiptPaymentStatusComponent,
        pathMatch: 'full',
      },
      { path: 'atomsuccessresponse', component: AtomsuccessresponseComponent },
      // {
      //   path: 'itemissue',
      //   component: ItemIssueComponent,
      //   pathMatch: 'full',
      // }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
