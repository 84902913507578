import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StudentReg } from '../model/index';
import { JsonPipe } from '@angular/common';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { User } from '../model/user.model';
import { map } from 'rxjs/operators';
import { StudentPromote,stFeeDiscount,UpdateFeeReceipt, StudentUserView } from '../model/index';
import { Observable, BehaviorSubject } from 'rxjs';
import { UpdateStudentView } from '../model/index'; 
import { userattendence } from '../model/index';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})

};
const apiUrl = environment.appUrl;


@Injectable({
  providedIn: 'root'
})
export class StudentService {
  public stid = 0;
  public sessionid:string = "0";
  public masterList: BehaviorSubject<any>=new BehaviorSubject<any>({});
  public schid = (<User>this.loginService.currentUserSubject.value ).schoolId;
  public studentInfo = null;
  constructor(private loginService: LoginService, private httpClient: HttpClient, public spinService: SpinnerVisibilityService) {
    // console.log((<User>this.loginService.currentUserSubject.value ));
    this.loginService.currentUserSubject.subscribe(m=>{
      this.schid =m?m.schoolId:0;
      
    })
  }
  public searchStudent = function( qry: string,ssid:number=0) {
    if(isNaN(ssid))
      ssid=0;
    return this.httpClient.get(apiUrl + '/api/Student/Search?qry=' + qry + '&schId=' + this.schid + '&SessionId='+ssid).
    pipe(map( m => { this.studentInfo = m;
        // Rajiv
        // if (this.studentInfo = null) {
        // this.studentInfo.stLeavingDate = null;        
        // }
                
        if (this.studentInfo.stLeavingDate == '1900-01-01T00:00:00') {
          this.studentInfo.stLeavingDate = null;
          
        } 
       
      return m;
    },
      error => {
        this.studentInfo = null;
      }));
  };
  public checkRegistration = function( qry: string, sessionId: number) {
    return this.httpClient.get(apiUrl + '/api/Student/CheckRegistration?qry=' + qry + '&schId=' + this.schid + '&sessionId=' + sessionId);
  };
  public getMasterList = function( ) {
    this.httpClient.get(apiUrl + '/api/Student/GetMaster?schId=' + this.schid).subscribe(m=>{
        this.masterList.next(m);
    })
  
  };
  public getStudentList = function(  stclass: number = 0, stSection: number = 0, stSession: number= 0) {
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Student/GetList?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession   );
  };
  public GetActiveStudentList = function(  stclass: number = 0, stSection: number = 0, stSession: number= 0) {
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Student/GetActiveStudentList?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession   );
  };
  public GetActiveStudentListForExport = function(  stclass: number = 0, stSection: number = 0, stSession: number= 0) {
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Student/GetActiveStudentListForExport?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession   );
  };
  public GetStudentUserList = function(  stclass: number = 0, stSection: number = 0, stSession: number= 0) {
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Setting/GetStudentUserList?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession   );
  };
  public GetStudentUser = function( oldsuid: string ) {
    return this.httpClient.get(apiUrl + '/api/Setting/GetStudentUser?schId=' + this.schid + '&oldsuid=' + oldsuid
   );
};

public GetStudentPass = function(oldsuid: string) {
  return this.httpClient.get(apiUrl + '/api/Setting/GetStudentPass?schId=' + this.schid + '&oldsuid=' + oldsuid
  );
};

public SaveStudentUserPass = function (stUser: StudentUserView) {

  return this.httpClient.post(apiUrl + '/api/Setting/SaveStudentUserPass', stUser, httpOptions);
};
public GetUserPassSMS = function( qry: string, StUser: string, Password: string ) {
  return this.httpClient.get(apiUrl + '/api/Setting/GetUserPassSMS?schId=' + this.schid + '&qry=' + qry
+ '&User=' + StUser + '&Password=' + Password );
};

public GetUserPassEmail = function( qry: string, StUser: string, Password: string ) {
  return this.httpClient.post(apiUrl + '/api/Setting/GetUserPassEmail?schId=' + this.schid + '&qry=' + qry
+ '&User=' + StUser + '&Password=' + Password );
};

  public getpendingStudentList = function(  stclass: string, stSection: string, stSession: number, userid: number ) {
        return this.httpClient.get(apiUrl + '/api/Setting/GetPendingStudentList?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession+ '&userid=' + userid   );
  };
  public getregistartionpointwiseList = function(  stclass: string, stSession: number, userid: number ) {
    return this.httpClient.get(apiUrl + '/api/Setting/getregistartionpointwiseList?schId=' + this.schid + '&stClass=' + stclass
 + '&stSession=' + stSession+ '&userid=' + userid   );
};
public getregistartionotherclassList = function(  stclass: string, stSession: number, userid: number ) {
  return this.httpClient.get(apiUrl + '/api/Setting/getregistartionotherclassList?schId=' + this.schid + '&stClass=' + stclass
+ '&stSession=' + stSession+ '&userid=' + userid   );
};
  public GetPendingSMS = function( qry: string, TotalAmount: string ) {
       return this.httpClient.get(apiUrl + '/api/Setting/GetPendingSMS?schId=' + this.schid + '&qry=' + qry
     + '&TotalAmount=' + TotalAmount );
  };

  public GetPendingEmail = function( qry: string, TotalAmount: string ) {
    return this.httpClient.post(apiUrl + '/api/Setting/GetPendingEmail?schId=' + this.schid + '&qry=' + qry
  + '&TotalAmount=' + TotalAmount );
};

  public GetAbsentSMS = function( qry: string) {
    return this.httpClient.get(apiUrl + '/api/Setting/GetAbsentSMS?schId=' + this.schid + '&qry=' + qry
   );
};

public GetAbsentEmail = function( qry: string) {
  return this.httpClient.post(apiUrl + '/api/Setting/GetAbsentEmail?schId=' + this.schid + '&qry=' + qry
);
};

public GetGeneralEMail = function( msgtype: string,qry: string) {
  return this.httpClient.post(apiUrl + '/api/Setting/GetGeneralEMail?schId=' + this.schid + '&msgtype=' + msgtype + '&qry=' + qry
);
};

public GetGeneralMasterSMS = function( msgtype: string,qry: string) {
  return this.httpClient.get(apiUrl + '/api/Setting/GetGeneralMasterSMS?schId=' + this.schid + '&msgtype=' + msgtype + '&qry=' + qry
);
};
  public GetGeneralSMS = function( qry: string, textSMS: string ) {
    return this.httpClient.get(apiUrl + '/api/Setting/GetGeneralSMS?schId=' + this.schid + '&qry=' + qry
  + '&textSMS=' + textSMS );
};
  public autoComplete = function(type: string, qry: string, ctid: number= 0, stid: number= 0) {
   // this.spinService.hide();
    this.spinService.visibility$.source.next(false);
    const param = '?type=' + type + '&qry=' + qry + '&ctid=' + ctid + '&stid=' + stid + '&schid=' + this.schid;
    return this.httpClient.get(apiUrl + '/api/AutoComplete/GetAutoComplete' + param);
    // this.spinService.show();
  };
  public saveStudentReg = function( stReg: StudentReg) {
    console.log(this.loginService.currentUserSubject);
    const stModel = {StudentId: this.stid, SchoolId: this.schid,
     StudentReg : JSON.stringify(stReg), UserId : this.loginService.currentUserSubject.value.userId };
    return this.httpClient.post(apiUrl + '/api/Student/SaveStudentReg', stModel, httpOptions);
  };
  public saveStudentRegImport = function( stReg: StudentReg) {
    console.log(this.loginService.currentUserSubject);
    const stModel = {StudentId: this.stid, SchoolId: this.schid,
     StudentReg : JSON.stringify(stReg), UserId : this.loginService.currentUserSubject.value.userId };
    return this.httpClient.post(apiUrl + '/api/Student/SaveStudentRegImport', stModel, httpOptions);
  };
  public managePromoteStudent = function (FromSessionId, ToSessionId, promotionData : StudentPromote[]) {
    const promotionModel = {SchoolId: this.schid,
      FromSessionId : FromSessionId,
      ToSessionId : ToSessionId,
      StudentPromote : promotionData };
     return this.httpClient.post(apiUrl + '/api/Student/ManagePromoteStudent', promotionModel, httpOptions);
  };

  public GetStudentPromoteList = function (classId,sectionId,sessionId,tosessionId) {
    let SchoolId =  this.schid;
     return this.httpClient.get(apiUrl + '/api/Student/GetStudentPromoteList?schId='+SchoolId+'&stClass='
     +classId+'&stSection='+sectionId+'&stSession='+sessionId + '&tostSession=' + tosessionId, httpOptions);
  };

  

  public GetStudentLastRollNo = function (classId,sectionId,sessionId) {
    let SchoolId =  this.schid;
     return this.httpClient.get(apiUrl + '/api/Student/GetStudentLastRollNo?schId='+SchoolId+'&stClass='+
     classId+'&stSection='+sectionId+'&stSession='+sessionId, httpOptions);
  };

  getPDF(studentid, domainName){
    let SchoolId =  this.schid;
    return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentProfilePDF?mode=1&qry=' +
    studentid+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
  }

  getICardPDF(studentid){
    let SchoolId =  this.schid;
    let SessionId =  0;

    // return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentICardPDF?mode=1&qry=' +
    // studentid+'&schId='+ SchoolId +'&domain='+ domainName, {  responseType: 'blob' as 'json', httpOptions });

    return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentICardPDF?qry='+studentid +
    '&schId='+ SchoolId, {  responseType: 'blob' as 'json', httpOptions });
  }

  getICardLandScapePDF(studentid){
    let SchoolId =  this.schid;
    let SessionId =  0;

    return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentICardLandScapePDF?qry='+studentid +
    '&schId='+ SchoolId, {  responseType: 'blob' as 'json', httpOptions });
  }


  public checkRegisteration = function( qry: string) {
    return this.httpClient.get(apiUrl + '/api/Registration/CheckRegistration?qry=' + qry + '&schId=' + this.schid + '&SessionId=0').
    pipe(map( m => { this.studentInfo = m;
        if (this.studentInfo.stLeavingDate == '1900-01-01T00:00:00') {
          this.studentInfo.stLeavingDate = null;
        }
      return m;
    },
      error => {
        this.studentInfo = null;
      }));
  };

  public searchStudentforRegisteration = function (qry: string) {
     return this.httpClient.get(apiUrl + '/api/registration/registrationsiblingsearchforstudent?AdmissionNo=' + qry 
     + '&schId=' + this.schid, httpOptions);
  };
  
  // public searchStudentforRegisteration = function( qry: string) {
  //   return this.httpClient.get(apiUrl + '/api/registration/registrationsiblingsearchforstudent?AdmissionNo=' + qry + '&schId=' + this.schid).
  //   pipe(map( m => { this.studentInfo = m;
  //       if (this.studentInfo.stLeavingDate == '1900-01-01T00:00:00') {
  //         this.studentInfo.stLeavingDate = null;
  //       }
  //     return m;
  //   },
  //     error => {
  //       this.studentInfo = null;
  //     }));
  // };

  public getFeeDiscountDetails():Observable<stFeeDiscount[]>{
    return this.httpClient.get<stFeeDiscount[]>(apiUrl +'/api/Student/GetStudentMonthFeeDiscount?qry='+this.stid +'&schid='+this.schid+'&SessionId='+this.sessionid)
  }
  public saveFeeDiscountDetails(feeList:stFeeDiscount[]):Observable<any>{
    let stFeeModel ={
      StudentId : this.stid,
      SchoolId : this.schid,
      StudentFeeView:feeList
    }
    return this.httpClient.post(apiUrl +'/api/Student/SaveStudentMonthFeeDiscount',stFeeModel,httpOptions);
  }
  public updateStudentReg = function (stReg: UpdateStudentView) {

    console.log(this.loginService.currentUserSubject);
    
    stReg.SchoolId = this.schid;
    stReg.UserId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.post(apiUrl + '/api/Student/UpdateStudentInfo', stReg, httpOptions);
  };

  public updateStudentPrevAmountReg = function (stReg: UpdateStudentView) {

    console.log(this.loginService.currentUserSubject);

    stReg.SchoolId = this.schid;

    return this.httpClient.post(apiUrl + '/api/Student/UpdateStudentPrevAmount', stReg, httpOptions);
  };
  public updateStudentMonthFeeDiscount = function (stReg: UpdateStudentView) {

    console.log(this.loginService.currentUserSubject);

    stReg.SchoolId = this.schid;
    stReg.UserId= this.loginService.currentUserSubject.value.userId;


    return this.httpClient.post(apiUrl + '/api/Student/UpdateStudentMonthFeeDiscount', stReg, httpOptions);
  };
  public getStudentMonthFeeDiscount = function (studentId, schoolId, sessionId) {
    
    return this.httpClient.get(apiUrl + '/api/Student/GetStudentMonthFeeDiscount?qry=' + studentId + 
      '&schId=' + schoolId + '&SessionId=' + sessionId, httpOptions);
  };

  public getSchoolMonthYearList = function (stclass: number = 0, stSection: number = 0, stSession: number = 0) {
// debugger;
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
    return this.httpClient.get(apiUrl + '/api/Fee/GetSchoolMonthYearListAgainstSessionList?schId=' + 
    this.schid + '&stSession=' + stSession);
  };
  public getStudentFeeReceiptList = function (fromDate,toDate, sessionId) {
    return this.httpClient.get(apiUrl + '/api/fee/GetStudentFeeReceiptList?schid=' + this.schid +
      '&stSession=' + sessionId + '&fromdate=' + fromDate + '&todate=' + toDate, httpOptions);
  };

  public GetStudentPaymentStatusList = function(){  
    let UserId = this.loginService.currentUserSubject.value.userId;  
    return this.httpClient.get(apiUrl + '/api/Fee/GetStudentPaymentStatusList?schId=' + this.schid + '&qry=' + UserId + '&mode=' + 1
    , httpOptions);
  };
  public GetStudentPaymentStatusUserWise = function(){    
    let UserId = this.loginService.currentUserSubject.value.userId;
    return this.httpClient.get(apiUrl + '/api/Fee/GetStudentPaymentStatusList?schId=' + this.schid + '&qry=' + UserId + '&mode=' + 2
    , httpOptions);
  };
  
  public getStudentFeeReceiptList_InStudentLogin = function (stdid, sessionId: number= 0) {
    sessionId = sessionId ? sessionId : 0;
    return this.httpClient.get(apiUrl + '/api/fee/GetStudentFeeReceiptList_InStudentLogin?schid=' + this.schid +
    '&stdid=' + stdid + '&stSession=' + sessionId, httpOptions);
  };
  public updateFeeReceiptStatus = function (stReg: UpdateFeeReceipt) {

    stReg.SchoolId = this.schid;
    stReg.UserId = this.loginService.currentUserSubject.value.userId;


    return this.httpClient.post(apiUrl + '/api/fee/UpdateFeeReceiptStatus', stReg, httpOptions);
  };

  public searchStudentRegistration = function( qry: string) {
    return this.httpClient.get(apiUrl + '/api/Registration/Getregistrationlistforstudent?RegistrationId=' + qry + '&schId=' + this.schid ).
      pipe(map( m => { this.studentInfo = m;
        console.log(this.studentInfo);
        return m;
      },
        error => {
        console.log(error);
        this.studentInfo = null;
      }));
  };

  public CheckStudentAttendence = function(  stclass: number = 0, stSection: number = 0, stSession: number= 0, 
    date: string = null) {
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Student/CheckStudentAttendence?schId=' + this.schid + '&stClass=' + stclass
     + '&stSection=' + stSection + '&stSession=' + stSession + '&attendencedate=' + date  );
  };

  public GetStudentAttendenceList = function(  stSession: number= 0, stclass: number = 0, stSection: number = 0,
    date: string = null) {
    stclass = stclass ? stclass : 0;
    stSection = stSection ? stSection : 0;
    stSession = stSession ? stSession : 0;
     return this.httpClient.get(apiUrl + '/api/Student/GetStudentAttendenceList?schId=' + this.schid + '&stSession=' + stSession
     + '&stClass=' + stclass + '&stSection=' + stSection  + '&attendencedate=' + date + '&mode=' + 1  );
  };
  public GetStudentAttendenceListDetail = function(AttendenceId= 0, stSession: number= 0, stclass: number = 0, stSection: number = 0 ){
    return this.httpClient.get(apiUrl + '/api/Student/GetStudentAttendenceListDetail?schId=' + this.schid + '&AttendenceId=' + AttendenceId
    + '&stSession=' + stSession + '&stClass=' + stclass + '&stSection=' + stSection, httpOptions);
  };

  public SaveStudentAttendence = function(attendenceData: any) {
    attendenceData.userId = this.loginService.currentUserSubject.value.userId;
    attendenceData.SchoolId = this.schid;
    return this.httpClient.post(apiUrl + '/api/Student/SaveStudentAttendence', attendenceData, httpOptions);
  };

  public GetStudentForSingle = function( qry: string,ssid:number) {
    return this.httpClient.get(apiUrl + '/api/Student/GetStudentForSingle?qry=' + qry + '&schId=' 
    + this.schid + '&SessionId='+ssid, httpOptions)
  };

  public GetWithdrawalList = function(stSession: number= 0) {
    return this.httpClient.get(apiUrl + '/api/Student/GetWithdrawalList?schId=' + this.schid
    + '&stSession=' + stSession, httpOptions);
  };

  public GetWithdrawalDetailList = function(wfcode: number= 0) {
    return this.httpClient.get(apiUrl + '/api/Student/GetWithdrawalDetailList?schId=' + this.schid
    + '&wfcode=' + wfcode, httpOptions);
  };

  public GetWithdrawalDetailForTC = function(wfcode: string) {
    return this.httpClient.get(apiUrl + '/api/Student/GetWithdrawalDetailForTC?schId=' + this.schid
    + '&wfcode=' + wfcode, httpOptions);
  };

  public manageWithdrawal = function(wfData : any, ){   
    return this.httpClient.post(apiUrl + '/api/Student/SaveWithdrawal', wfData, httpOptions);
  };

  public GetTransferCertList = function(stSession: number= 0) {
    return this.httpClient.get(apiUrl + '/api/Student/GetTransferCertList?schId=' + this.schid
    + '&stSession=' + stSession, httpOptions);
  };

  public GetTransferCertDetailList = function(tccode: number= 0) {
    return this.httpClient.get(apiUrl + '/api/Student/GetTransferCertDetailList?schId=' + this.schid
    + '&tccode=' + tccode, httpOptions);
  };

  public manageTransferCert = function(tcData : any){
    // tcData.userId = this.loginService.currentUserSubject.value.userId;
    // tcData.schId = this.schid;
    return this.httpClient.post(apiUrl + '/api/Student/SaveTransferCert', tcData, httpOptions);
  };
  getPDFWithdrawal(studentid, domainName){
    let SchoolId =  this.schid;
    return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentWithdrawalPDF?mode=1&qry=' +
    studentid+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
  }
  getPDFTransferCertificate(studentid, domainName){
    let SchoolId =  this.schid;
    return this.httpClient.post<Blob>(apiUrl + '/api/Student/SaveStudentTransferCertificatePDF?mode=1&qry=' +
    studentid+'&schId='+ SchoolId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
  }

  public GetTotalNoOfStudent = function() {
    return this.httpClient.get(apiUrl + '/api/Student/GetTotalNoOfStudent?schId=' + this.schid, httpOptions);
  };

  public GetTotalNoOfGilrsStudent = function() {
    return this.httpClient.get(apiUrl + '/api/Student/GetTotalNoOfGilrsStudent?schId=' + this.schid, httpOptions);
  };

  public GetTotalNoOfBoysStudent = function() {
    return this.httpClient.get(apiUrl + '/api/Student/GetTotalNoOfBoysStudent?schId=' + this.schid, httpOptions);
  };

  public GetTotalNoOfNewStudent = function() {
    return this.httpClient.get(apiUrl + '/api/Student/GetTotalNoOfNewStudent?schId=' + this.schid, httpOptions);
  };

  public GetTotalHolidaysList = function() {
    return this.httpClient.get(apiUrl + '/api/Student/GetTotalHolidaysList?schId=' + this.schid, httpOptions);
  };

  public GetTotalBirthdayStudentList = function() {
    return this.httpClient.get(apiUrl + '/api/Student/GetTotalBirthdayStudentList?schId=' + this.schid, httpOptions);
  };

  public GetStudentOldAdmissionNo = function(UserId) {
    return this.httpClient.get(apiUrl + '/api/Fee/GetStudentOldAdmissionNo?stid=' + UserId);
 };

 public GetStudentForSingleForStudentLogin = function( qry: number,ssid:number= 0) {
  return this.httpClient.get(apiUrl + '/api/Student/GetStudentForSingle__InStudentLogin?qry=' + qry + '&schId=' 
  + this.schid + '&SessionId='+ssid, httpOptions)
};

public CheckUserAttendence = function(  stuser: number = 0, date: string = null) {
   return this.httpClient.get(apiUrl + '/api/User/CheckUserAttendence?schid=' + this.schid + '&userid=' + stuser
   + '&attendencedate=' + date  );
};
public getUserAttendence = function(UserId: number, date: string = null) {
  return this.httpClient.get(apiUrl + '/api/User/GetUserAttendence?mode=' + 1 + '&schid=' + this.schid +
   '&userid=' + UserId + '&attendencedate=' + date, httpOptions);
};
public getAllUserAttendence = function(UserId: number,date: string = null) {
  return this.httpClient.get(apiUrl + '/api/User/GetUserAttendence?mode=' + 2 + '&schid=' + this.schid +
  '&userid=' + UserId + '&attendencedate=' + date, httpOptions);
};
public getLoginUserAttendence = function(UserId: number, date: string = null) {
  UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.get(apiUrl + '/api/User/GetUserAttendence?mode=' + 3 + '&schid=' + this.schid +
   '&userid=' + UserId + '&attendencedate=' + date, httpOptions);
};

public getUserAttendenceListBetweenDate = function(UserId: number,fromdate: string = null, todate: string = null) {
  return this.httpClient.get(apiUrl + '/api/User/GetUserAttendenceList?mode=' + 1 + '&schid=' + this.schid +
  '&userid=' + UserId + '&fromattendencedate=' + fromdate + '&toattendencedate=' + todate, httpOptions);
};

public getAllUserAttendenceListBetweenDate = function(UserId: number,fromdate: string = null, todate: string = null) {
  return this.httpClient.get(apiUrl + '/api/User/GetUserAttendenceList?mode=' + 2 + '&schid=' + this.schid +
  '&userid=' + UserId + '&fromattendencedate=' + fromdate + '&toattendencedate=' + todate, httpOptions);
};

public manageUserattendence = function (userattendence: any) {
//  userattendence.UserId = this.loginService.currentUserSubject.value.userId;
//  userattendence.SchoolId = this.schoolId;
//  userattendence.us_present = true;
  return this.httpClient.post(apiUrl + '/api/User/SaveUserAttendence', userattendence, httpOptions);
};
//Term1
printterm1PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintTerm1PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

printterm1Class3To10PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintTerm1Class3To10PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

printterm1Class11To12PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintTerm1Class11To12PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailClass1and2ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardClass1and2MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailClass1and2ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardClass1and2MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

MailClass3To10ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardClass3To10MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailClass3To10ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardClass3To10MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

MailClass11To12ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardClass11To12MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailClass11To12ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardClass11To12MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}
//Unit1
printunit1PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintUnit1PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailUnit1ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardUnit1MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailUnit1ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardUnit1MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}
//Unit2
printunit2PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintUnit2PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailUnit2ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardUnit2MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailUnit2ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardUnit2MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}
//Term2
printterm2PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintTerm2PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

printterm2Class3To10PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintTerm2Class3To10PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

printterm2Class11To12PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintTerm2Class11To12PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailTerm2Class1and2ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class1and2MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailTerm2Class1and2ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class1and2MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

MailTerm2Class3To10ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class3To10MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailTerm2Class3To10ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class3To10MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

MailTerm2Class11To12ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class11To12MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailTerm2Class11To12ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class11To12MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

//PB1
printpb1PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintPB1PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailPB1ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB1MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailPB1ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB1MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}


PrintPB1_With_IAClass3To10PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintPB1_With_IAClass3To10PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

PrintPB1_With_IAClass11To12PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintPB1_With_IAClass11To12PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}


MailPB1_With_IAClass3To10ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB1_With_IAClass3To10MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailPB1_With_IAClass3To10ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardTerm2Class3To10MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

MailPB1_With_IAClass11To12ReportCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB1_With_IAClass11To12MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}

MailPB1_With_IAClass11To12ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB1_With_IAClass11To12MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}
//PB2
MailPPB2_With_IAClass3To10MailDetail(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB2_With_IAClass3To10MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailPB2_With_IAClass3To10ReportCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB2_With_IAClass3To10MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}
PrintPB2_With_IAClass3To10PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintPB2_With_IAClass3To10PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
PrintPB2_With_IAClass11To12PDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintPB2_With_IAClass11To12PDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailPB2_With_IAClass11To12MailDetail(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB2_With_IAClass11To12MailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailPB2_With_IAClass11To12MailDetailOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetReportCardPB2_With_IAClass11To12MailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}
public GetConsolidatedReportCardExport1and2 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetConsolidatedReportCardExport?mode=1&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetConsolidatedReportCardExport3To9 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetConsolidatedReportCardExport?mode=2&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetConsolidatedReportCardExport10 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetConsolidatedReportCardExport?mode=3&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetConsolidatedReportCardExport11 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetConsolidatedReportCardExport?mode=4&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetConsolidatedReportCardExport12 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetConsolidatedReportCardExport?mode=5&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};


public GetAverageReportCardExport1and2 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExport1To2?mode=1&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardExport3To9 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExport3To9?mode=2&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardExport10 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExport10?mode=3&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardExport11 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExport11?mode=4&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardExport12 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExport12?mode=5&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardTotalNoStudentExport1and2 = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardTotalNoStudentExport1To2?mode=1&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardTotalNoStudentExport3To9 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardTotalNoStudentExport3To9?mode=2&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardTotalNoStudentExport10 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardTotalNoStudentExport10?mode=3&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardTotalNoStudentExport11 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardTotalNoStudentExport11?mode=4&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};
public GetAverageReportCardTotalNoStudentExport12 = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardTotalNoStudentExport12?mode=5&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession   );
};

public GetAverageReportCardUnit1ExamWiseExport = function(  stclass: number = 0,stSection: number = 0, stSession: number= 0, examsubtype: number=0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
  examsubtype = examsubtype ? examsubtype : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExamWiseExportUnit1?mode=1&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession + '&exstId=' + examsubtype  );
};
public GetAverageReportCardTerm1ExamWiseExport = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0, exstId: number=0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
  exstId = exstId ? exstId : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExamWiseExportTerm1?mode=2&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession + '&exstId=' + exstId  );
};
public GetAverageReportCardUnit2ExamWiseExport = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0, exstId: number=0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
  exstId = exstId ? exstId : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExamWiseExportUnit2?mode=3&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession + '&exstId=' + exstId  );
};
public GetAverageReportCardTerm2ExamWiseExport = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0, exstId: number=0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
  exstId = exstId ? exstId : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExamWiseExportTerm2?mode=4&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession + '&exstId=' + exstId  );
};
public GetAverageReportCardPB1ExamWiseExport = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0, exstId: number=0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
  exstId = exstId ? exstId : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExamWiseExportPB1?mode=5&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession + '&stSession=' + stSession + '&exstId=' + exstId  );
};
public GetAverageReportCardPB2ExamWiseExport = function(  stclass: number = 0, stSection: number = 0,stSession: number= 0, exstId: number=0) {
  stclass = stclass ? stclass : 0;  
  stSection = stSection ? stSection : 0;
  stSession = stSession ? stSession : 0;
  exstId = exstId ? exstId : 0;
   return this.httpClient.get(apiUrl + '/api/Student/GetAverageReportCardExamWiseExportPB2?mode=6&schId=' + this.schid + '&stClass=' + stclass
   + '&stSection=' + stSection + '&stSession=' + stSession + '&exstId=' + exstId   );
};

printadmitcardPDF(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/PrintAdmitCardPDF?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
//Admit Card
MailAdmitCard(studentid,sessionid,exstid,userid, domainName){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetAdmitCardMailDetail?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName, {  responseType: 'blob' as 'json', httpOptions });
}
MailAdmitCardOnSingleMailID(studentid,sessionid,exstid,userid, domainName,email){
  let SchoolId =  this.schid;
  let UserId = this.loginService.currentUserSubject.value.userId;
  return this.httpClient.post<Blob>(apiUrl + '/api/Student/GetAdmitCardMailDetailOnSingleMailID?mode=1&qry=' +
  studentid+'&schId='+ SchoolId+'&sesId='+ sessionid+'&exstId='+ exstid+'&userId='+ UserId +'&domain='+domainName+'&email='+email, {  responseType: 'blob' as 'json', httpOptions });
}

}
